import { createMuiTheme } from '@material-ui/core/styles';

// We can override palette, ... here to customize the theme to our branding requirements
export const theme = createMuiTheme({
	supportedLanguages: ["en_US"],
	customerLogoImageSrc: "/logo.png",
	overrides: {
		MuiInput: {
			root: {
				'&$disabled': {
					color: "#616161",
				}
			}
		},
		MuiFormLabel: {
			root: {
				'&$disabled': {
					color: "rgba(0, 0, 0, 0.54)",
				}
			}
		},
		MuiToggleButton: {
			root: {
				color: "inherit",
				/*
				"&.Mui-selected": {
					color: "#ff0000",
					backgroundColor: '#0000ff'
				},
				*/
				/*
				"&:hover": {
					color: '#00ff00',
					backgroundColor: '#ff0000'
				}
				*/
			}
		},
	},
	shape: {
		borderRadius: "10px",
	},
	palette: {
		primary: {
			main: "#0000FF",
		},
		secondary: {
			main: "#211A1D",
		},
	},
	typography: {
		useNextVariants: true,

		body1: {
			fontStretch: "125%",
		},
		body2: {
			fontStretch: "125%",
		},
		h5: {
			fontStretch: "125%",
		},
		
		fontFamily: [
	      '"Roboto Flex"',
	      'sans-serif'
		].join(","),
	},
});

//console.log(theme);