import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';  
import Dropzone from 'react-dropzone';
import SnackbarContentWrapper from './SnackbarContentWrapper';
import PreviewList from './PreviewList';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import history from './history';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import FolderIcon from '@material-ui/icons/Folder';
import FileIcon from '@material-ui/icons/InsertDriveFile';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';
import CloseIcon from '@material-ui/icons/Close';

import { ReactReader } from 'react-reader'

const DialogTitle = withStyles(theme => ({
	root: {
		borderBottom: `1px solid ${theme.palette.divider}`,
		margin: 0,
		padding: theme.spacing.unit * 2,
	},
	downloadButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
	},
}))(props => {
	const { children, classes, onDownload } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root}>
			<Typography variant="h6">{children}</Typography>
				{
					onDownload ? (
						<Tooltip title={props.downloadLabel} disableFocusListener>
							<IconButton aria-label="Download" disabled={props.selectedFile == null} className={classes.downloadButton} onClick={onDownload}>
								<CloudDownloadIcon/>
							</IconButton>
						</Tooltip>
					) : null
				}
		</MuiDialogTitle>
	);
});

const styles = theme => ({
	'@keyframes progress': {
		'0%': {
			backgroundPosition: '0 0',
		},
		'100%': {
			backgroundPosition: '-70px 0',
		},
	},
	dropZone: {
		borderRadius: "10px",
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		border: 'dashed',
		borderColor: '#949494',
		cursor: 'pointer',
		boxSizing: 'border-box',
	},
	disabledDropZone: {
		borderRadius: "10px",
		position: 'relative',
		width: '100%',
		minHeight: '100px',
		border: 'dashed',
		borderColor: '#C8C8C8',
		cursor: 'default',
		boxSizing: 'border-box',
	},
	stripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #F0F0F0, #F0F0F0 25px, #C8C8C8 25px, #C8C8C8 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	rejectStripes: {
		border: 'solid',
		backgroundImage: 'repeating-linear-gradient(-45deg, #fc8785, #fc8785 25px, #f4231f 25px, #f4231f 50px)',
		animation: 'progress 2s linear infinite !important',
		backgroundSize: '150% 100%',
	},
	dropzoneTextStyle:{
		paddingLeft: theme.spacing.unit,
		paddingRight: theme.spacing.unit,
		paddingTop: theme.spacing.unit,
		textAlign: 'center',
	},
	dropzoneParagraph:{
		top: 0,
	},
	snackbar: {
		"& ::-moz-selection": { /* Code for Firefox */
			color: "#000000",
			background: "#FFFFFF",
		},
		"& ::selection": {
			color: "#000000",
			background: "#FFFFFF",
		},
	},
	nested: {
		paddingLeft: theme.spacing.unit * 2,
	},
	dialogPaper: {
		minHeight: "50vh",
		maxHeight: "80vh",
		minWidth: "50vw",
		maxWidth: "80vw",
	},
	previewDialogPaper: {
		minHeight: "95vh",
		maxHeight: "95vh",
		minWidth: "95vw",
		maxWidth: "95vw",
	},
	dialogContent: {
		display: "flex",
		flexGrow: "1",
		paddingTop: "16px",
	},
	docViewer: {
		display: "flex",
		flexGrow: "1",
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing.unit,
		top: theme.spacing.unit,
	},
});

class DropzoneArea extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			openSnackBar: false,
			deleteConfirmationDialogOpened: false,
			snackbarMessage: '',
			snackbarVariant: 'success',
			zipBrowserOpen: false,
			previewOpen: false,
			epubPreviewOpen: false,
			treeStructure: [],
		}

		this.handleClick = this.handleClick.bind(this);
		this.handleClickOnFile = this.handleClickOnFile.bind(this);
		this.handleDownloadItem = this.handleDownloadItem.bind(this);
		this.handleDeleteClick = this.handleDeleteClick.bind(this);
		this.handleRemove = this.handleRemove.bind(this);
		this.handlePasteFromClipboard = this.handlePasteFromClipboard.bind(this);
	}
	
	handlePasteFromClipboard(event) {
		if (!this.props.disabled) {
			var items = (event.clipboardData || event.originalEvent.clipboardData).items;
			for (var index in items) {
				var item = items[index];
				if (item.kind === 'file') {
					if (this.props.fileObjects.length + 1 > this.props.filesLimit) {
						this.setState({
							openSnackBar: true,
							snackbarMessage: `Maximum allowed number of files exceeded. Only ${this.props.filesLimit} allowed`, 
							snackbarVariant: 'error'
						});
					}
					else {
						var blob = item.getAsFile();
						this.props.onDrop(blob);
					}
					break;
				}
			}
		}
	}
	
	handleClickOnFile(event, item) {
		this.setState({
			selectedFile: item.path,
		});
	}
	
	handleClick(event, item) {
		item.open = !item.open;
		this.setState({
			treeStructure: this.state.treeStructure,
			selectedFile: null,
		});
	}
	
	onDrop(files) {
		if (this.props.fileObjects.length + files.length > this.props.filesLimit) {
			this.setState({
				openSnackBar: true,
				snackbarMessage: `Maximum allowed number of files exceeded. Only ${this.props.filesLimit} allowed`, 
				snackbarVariant: 'error'
			});
		}
		else {
			var count = 0;
			var message = '';
			files.forEach((file) => {
				if (this.props.onDrop) {
					this.props.onDrop(file);
				}
				message += `File ${file.name} successfully uploaded. `;
				count++; // we cannot rely on the index because this is asynchronous
				if (count === files.length){
					// display message when the last one fires
					this.setState({
						openSnackBar: false,
						snackbarMessage: message, 
						snackbarVariant: 'success'
					});
				}
			});
		}
	}
	
	handlePreview = fileIndex => event => {
		event.preventDefault();
 		event.stopPropagation();
 		
 		/* OpenOffice Online editor
		let url = "/admin/" + this.props.entity + "/" + this.props.entityId + "/" + this.props.attributeName + "/edit";
		history.push(url);
		*/

		const {fileObjects} = this.props;
		const fileObject = fileObjects.filter((fileObject, i) => { return i === fileIndex})[0];
 		
		if (fileObject.type == "application/pdf" || fileObject.type == "audio/mpeg" || fileObject.type == "audio/wav" || fileObject.type == "audio/x-wav" || fileObject.type == "video/mp4") {
			window.open(this.props.context.baseUrl + "/wopi/files/" + this.props.entity.replace(".", "/") + "/" + this.props.entityId + "/" + this.props.attributeName + "/contents?inline=true&access_token=" + this.props.context.accessToken);
		}
		else if (fileObject.type == "image/bmp"
				|| fileObject.type == "application/vnd.oasis.opendocument.text"
				|| fileObject.type == "application/vnd.oasis.opendocument.spreadsheet"
				|| fileObject.type == "application/vnd.oasis.opendocument.presentation"
				|| fileObject.type == "application/msword"
				|| fileObject.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
				|| fileObject.type == "text/htm"
				|| fileObject.type == "text/html"
				|| fileObject.type == "image/jpg"
				|| fileObject.type == "image/jpeg"
			//	|| fileObject.type == "application/pdf"
				|| fileObject.type == "image/png"
				|| fileObject.type == "application/vnd.ms-powerpoint"
				|| fileObject.type == "application/vnd.openxmlformats-officedocument.presentationml.presentation"
			//	|| fileObject.type == "image/tiff" - No funciona
				|| fileObject.type == "application/vnd.ms-excel"
				|| fileObject.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
	 		this.setState({
				previewOpen: true,
				previewUrl: this.props.context.baseUrl + "/wopi/files/" + this.props.entity.replace(".", "/") + "/" + this.props.entityId + "/" + this.props.attributeName + "/contents?inline=true&access_token=" + this.props.context.accessToken,
			});
		}
		else if (fileObject.type == "application/epub+zip") {
	 		this.setState({
				epubPreviewOpen: true,
				previewUrl: this.props.context.baseUrl + "/wopi/files/" + this.props.entity.replace(".", "/") + "/" + this.props.entityId + "/" + this.props.attributeName + "/contents?inline=true&access_token=" + this.props.context.accessToken,
			});
		}
	}
	
	handleView = fileIndex => event => {
		event.preventDefault();
 		event.stopPropagation();

 		/*
		let url = "/admin/" + this.props.entity + "/" + this.props.entityId + "/" + this.props.attributeName + "/edit";
		history.push(url);
		*/
		this.props.context.showActivityIndicator();
		
		fetch(this.props.context.baseUrl + "/wopi/files/" + this.props.entity.replace("\.", "/") + "/" + this.props.entityId + "/" + this.props.attributeName + "/entries?access_token=" + this.props.context.accessToken, {
			method: "GET",
			keepalive: true
		})
		.then(response => response.json())
		.then(json => {
			json.forEach(node => this.calculatePaths(node));
			
			this.props.context.hideActivityIndicator();
			this.setState({
				zipBrowserOpen: true,
				treeStructure: json,
				selectedFile: null,
			});
		})
		.catch(error => {
			console.log(error);
		});
	}

	calculatePaths(node) {
		if (node.path == null) {
			node.path = node.name;
		}
		node.children.forEach(child => {
			child.path = node.path + "/" + child.name;
			this.calculatePaths(child);
		});
	}
	
	setQuotes(attribute, value) {
		const hasQuotes = (attribute.type === "TEXT"
			|| attribute.type === "DATE"
			|| attribute.type === "TIMESTAMP"
			|| attribute.type === "VARCHAR"
			|| attribute.type === "CHAR"
			|| attribute.type === "TIME"
			|| attribute.type === "INTERVAL"
			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
			|| attribute.type === "TIME_WITH_TIME_ZONE"
			|| attribute.type === "POINT"
			|| attribute.type === "POLYGON"
		);
		return (hasQuotes ? '"' : '') + value + (hasQuotes ? '"' : '');
	}
	
	handleDownloadItem(event) {
		event.stopPropagation();
		
		this.props.context.showActivityIndicator();
		
		let link = document.createElement("a");
		
		link.download = (this.state.selectedFile.lastIndexOf("/") == (-1) ? this.state.selectedFile : this.state.selectedFile.substr(this.state.selectedFile.lastIndexOf("/") + 1));
		link.href = this.props.context.baseUrl + "/wopi/files/" + this.props.entity.replace(".", "/") + "/" + this.props.entityId + "/" + this.props.attributeName + "/entry?entryPath=" + this.state.selectedFile + "&access_token=" + this.props.context.accessToken;
		//console.log(link.download);
		//console.log(link.href);
		document.body.appendChild(link);
		link.click();
		link.remove();
		
		this.props.context.hideActivityIndicator();
	}
	
	handleDownload = fileIndex => event => {
		event.stopPropagation();
		
		this.props.context.showActivityIndicator();
		
		const model = this.props.context.model;
		const entityModel = model.entities[this.props.entity];
		
		const {fileObjects} = this.props;
		const fileObject = fileObjects.filter((fileObject, i) => { return i === fileIndex})[0];
		
		let keyAttribute = Object.values(entityModel.keys).filter(key => key.primaryKey)[0].attributes[0];
		
		const query = 
			'{ ' +
			'	result:' + this.props.entity.replace(".", "_") + 'List(' +
			'		limit: 1' +
			'       where: {' + keyAttribute.name + ': {EQ: ' + this.setQuotes(keyAttribute, this.props.entityId) + '}}' +
			'	) {' +
			'		' + this.props.attributeName + (this.props.attributeIsArray ? '(limit: 1 offset: ' + fileIndex + ')' : '') + ' { oid }' +
			'	} ' +
			'}';
		
		const variables = {
			authorization: this.props.context.accessToken
		};
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			keepalive: true,
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json != null
					&& json.errors != null
					&& json.errors[0] != null
                    && (json.errors[0].message == "SessionTimeout" 
                            || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
				document.location = '/login';
			}
			
			let result = json.data["result"][0][this.props.attributeName];
			if (this.props.attributeIsArray) {
				result = result[0];
			}
			
			let link = document.createElement("a");
			
			link.download = fileObject.name;
			link.href = this.props.context.baseUrl + "/wopi/files/" + this.props.entity.replace(".", "/") + "/" + this.props.entityId + "/" + this.props.attributeName + "/contents?access_token=" + this.props.context.accessToken;
			//console.log(link.download);
			//console.log(link.href);
			document.body.appendChild(link);
			link.click();
			link.remove();
			
			this.props.context.hideActivityIndicator();
		})
		.catch(error => {
			console.log(error);
			console.log(request);
		});
	}
	
	handleDeleteClick = fileIndex => event => {
		event.stopPropagation();
		event.preventDefault();
		this.setState({
			deleteConfirmationDialogOpened: true,
			fileIndex: fileIndex,
		});
	}
	
	handleRemove(event) {
		const {fileObjects} = this.props;
		const fileObject = fileObjects.filter((fileObject, i) => { return i === this.state.fileIndex})[0];
		fileObjects.splice(this.state.fileIndex, 1);
		if (this.props.onDelete) {
			this.props.onDelete(fileObject);
		}
		this.setState({
			deleteConfirmationDialogOpened: false,
			openSnackBar: false,
			snackbarMessage: ('File ' + fileObject.name+ ' removed'),
			snackbarVariant: 'info'
		});
	}
	
	handleCopyShortUrlToClipboard = fileIndex => event => {
		event.stopPropagation();
		const {fileObjects} = this.props;
		const fileObject = fileObjects.filter((fileObject, i) => { return i === fileIndex})[0];
		
		fetch(this.props.context.baseUrl + "/s/" + this.props.entity.replace("\.", "/") + "/" + this.props.entityId + "/" + this.props.attributeName, {
			method: "GET",
			keepalive: true
		})
		.then(response => response.text())
		.then(text => {
			const element = document.createElement('textarea');
			element.value = this.props.context.baseUrl + text;
			document.body.appendChild(element);
			element.select();
			document.execCommand('copy');
			document.body.removeChild(element);
			
			this.setState({
				openSnackBar: true,
				snackbarMessage: this.props.context.baseUrl + text,
				snackbarVariant: 'info'
			});
		})
		.catch(error => {
			console.log(error);
		});
	}
	
	handleDropRejected(rejectedFiles, evt) {
		var message = '';
		rejectedFiles.forEach((rejectedFile) => {
			message = this.props.t("fileRejected").replace("${filename}", rejectedFile.name);
			if (this.props.acceptedFiles != null && this.props.acceptedFiles.length > 0 && !this.props.acceptedFiles.includes(rejectedFile.type)){
				message += " " + this.props.t("fileTypeNotSupported");
			}
			if (rejectedFile.size > this.props.maxFileSize) {
				let limit = Number((this.props.maxFileSize / Math.pow(1024, Math.floor(Math.log2(this.props.maxFileSize) / 10))).toFixed(2)) + [' bytes', ' kB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB'][Math.floor(Math.log2(this.props.maxFileSize) / 10)];
				message += " " + this.props.t("fileTooBig").replace("${limit}", limit);
			}
		});
		if (this.props.onDropRejected){
			this.props.onDropRejected(rejectedFiles, evt);
		}
		this.setState({
			openSnackBar: true,
			snackbarMessage: message,
			snackbarVariant: 'error'
		});
	}
	
	onCloseSnackbar = () => {
		this.setState({
			openSnackBar: false,
		});
	};
	
	onCloseDialog = () => {
		this.setState({
			zipBrowserOpen: false,
		});
	};

	onPreviewCloseDialog = () => {
		this.setState({
			previewOpen: false,
			epubPreviewOpen: false,
		});
	};

	renderNode(node) {
		const { classes, t } = this.props;
		
		return node.map(item => (
			!item.isDirectory &&
				<ListItem button onClick={(event) => this.handleClickOnFile(event, item)} className={classes.nested} key={item.name + "-item"}>
					<ListItemIcon>
						<FileIcon/>
					</ListItemIcon>
					<ListItemText inset primary={item.name}/>
		        </ListItem>
		)
		||
		(
			<>
				<ListItem button onClick={(event) => this.handleClick(event, item)} className={classes.nested} key={item.name + "-item"}>
					<ListItemIcon>
						<FolderIcon/>
					</ListItemIcon>
					<ListItemText inset primary={item.name}/>
						{item.open ? <ExpandLess /> : <ExpandMore />}
				</ListItem>
				<Collapse in={item.open} timeout="auto" unmountOnExit className={classes.nested} key={item.name + "-item-menu"}>
					<List component="div" disablePadding>
						{ this.renderNode(item.children) }
					</List>
				</Collapse>
			</>
		));
	};
	
	render() {
		const {classes} = this.props;
		
		return (
			<Fragment>
				<Dropzone
					disabled={this.props.disabled}
					accept={this.props.acceptedFiles.join(',')}
					onDrop={this.onDrop.bind(this)}
					onDropRejected={this.handleDropRejected.bind(this)}
					className={(this.props.disabled ? classes.disabledDropZone : classes.dropZone)}
					acceptClassName={classes.stripes}
					rejectClassName={classes.rejectStripes}
					maxSize={this.props.maxFileSize}
					onPaste={this.handlePasteFromClipboard}
				>
					{
						!this.props.disabled
								&&
						<div className={classes.dropzoneTextStyle}>
							<Typography variant="button" 
								className={classes.dropzoneParagraph}
								color="inherit"
								noWrap
							>
								{this.props.dropzoneText}
							</Typography>
						</div>
					}
					{
						<PreviewList
							disabled={this.props.disabled}
							fileObjects={this.props.fileObjects} 
							handleDownload={this.handleDownload.bind(this)}
							handlePreview={this.handlePreview.bind(this)}
							handleView={this.handleView.bind(this)}
							handleRemove={this.handleDeleteClick.bind(this)}
							handleCopyShortUrlToClipboard={this.handleCopyShortUrlToClipboard.bind(this)}
							browseZip={this.props.browseZip}
						/>
					}
				</Dropzone>
				{
					this.props.showAlerts 
						&&
					<Snackbar
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						open={this.state.openSnackBar}
						autoHideDuration={6000}
						onClose={this.onCloseSnackbar}
					>
						<SnackbarContentWrapper
							onClose={this.onCloseSnackbar}
							variant={this.state.snackbarVariant}
							message={this.state.snackbarMessage}
							className={classes.snackbar}
						/>
					</Snackbar>
				}
				<Dialog scroll="paper" open={this.state.zipBrowserOpen} onClose={this.onCloseDialog} classes={{ paper: classes.dialogPaper }}>
					<DialogTitle onDownload={this.handleDownloadItem} downloadLabel={this.props.t("download")} selectedFile={this.state.selectedFile}>{this.props.t("fileContents")}</DialogTitle>
					<DialogContent>
						<List component="nav">
							{ this.renderNode(this.state.treeStructure)	}
						</List>					
					</DialogContent>
				</Dialog>
				<Dialog 
						scroll="paper" 
						open={this.state.previewOpen} 
						onClose={this.onPreviewCloseDialog} 
						classes={{ paper: classes.previewDialogPaper }}>
					<MuiDialogTitle>
						<IconButton aria-label="close" className={classes.closeButton} onClick={this.onPreviewCloseDialog}>
							<CloseIcon />
				        </IconButton>
					</MuiDialogTitle>
					<DialogContent className={classes.dialogContent}>
						<DocViewer 
								className={classes.docViewer}
								pluginRenderers={DocViewerRenderers}
								documents={[{uri: this.state.previewUrl}]}
								config={{
									header: {
										disableHeader: true,
										disableFileName: true,
									}
								}}
						/>
					</DialogContent>
				</Dialog>
				<Dialog 
						scroll="paper" 
						open={this.state.epubPreviewOpen} 
						onClose={this.onPreviewCloseDialog} 
						classes={{ paper: classes.previewDialogPaper }}>
					<MuiDialogTitle>
						<IconButton aria-label="close" className={classes.closeButton} onClick={this.onPreviewCloseDialog}>
							<CloseIcon />
				        </IconButton>
					</MuiDialogTitle>
					<DialogContent className={classes.dialogContent}>
						<div style={{ height: "calc(95vh - 84px)", width: "100%" }}>
							<ReactReader
								epubInitOptions={{
									openAs: 'epub'
								}}
								location={this.state.location}
								locationChanged={epubcifi => this.setState({location: epubcifi})}
								url={this.state.previewUrl}
							/>
						</div>
					</DialogContent>
				</Dialog>
				<Dialog style={{zIndex: 10000}}
						open={this.state && this.state.deleteConfirmationDialogOpened}
						onClose={event => this.setState({deleteConfirmationDialogOpened: false})}>
					<DialogTitle>{this.props.t('deleteConfirmation')}</DialogTitle>
					<DialogContent>
						<DialogContentText>{this.props.t('deleteQuestion') + " " + this.props.t('document') + "?"}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={event => this.setState({deleteConfirmationDialogOpened: false})}>{this.props.t('cancel')}</Button>
						&nbsp;
						<Button onClick={this.handleRemove}>{this.props.t('delete')}</Button>
					</DialogActions>
				</Dialog>
			</Fragment>
		)
	}
}

DropzoneArea.defaultProps = {
	acceptedFiles: ['image/*', 'video/*', 'application/*'],
	filesLimit: 5,
	maxFileSize: 100000000,
	dropzoneText: 'Drag and drop an image file here or click',
	showAlerts: true,
	clearOnUnmount: true,
	onDrop: () => {},
	onDropRejected: () => {},
	onDelete: () => {},
	fileObjects: [],
	disabled: false,
}

DropzoneArea.propTypes = {
	acceptedFiles: PropTypes.array,
	filesLimit: PropTypes.number,
	maxFileSize: PropTypes.number,
	dropzoneText: PropTypes.string,
	showAlerts: PropTypes.bool,
	clearOnUnmount: PropTypes.bool, 
	onDrop: PropTypes.func,
	onDropRejected: PropTypes.func,
	onDelete: PropTypes.func,
	fileObjects: PropTypes.array,
	disabled: PropTypes.bool,
	entity: PropTypes.string.isRequired,
	entityId: PropTypes.string,
	attributeName: PropTypes.string,
	attributeIsArray: PropTypes.bool, 
	browseZip: PropTypes.bool,
}

export default withStyles(styles)(withContext(withI18n()(DropzoneArea)));
