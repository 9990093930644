import { throttle } from "throttle-debounce";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import i18n from 'i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { localModel } from './localModel';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import SaveIcon from '@material-ui/icons/Save';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/EditOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import history from './history';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EntityList from './EntityList';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import AutoComplete from './AutoComplete';
import ChipInput from 'material-ui-chip-input';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PasswordField from 'material-ui-password-field';
import DropzoneArea from './DropzoneArea';
import SignaturePad from './SignaturePad';
import { ChromePicker } from 'react-color';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import { refreshPersonalization, refreshModels } from './refresh';
import Modeler from 'bpmn-js/lib/Modeler';
import BpmnEditor from './BpmnEditor';
import { getParameter } from './request';

import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
import SettingsIcon from '@material-ui/icons/Settings';

import Barcode from './Barcode';

import BlocklyComponent from './BlocklyComponent';
import { Block, Value, Field, Shadow } from './blockly';
import BlocklyJS from 'blockly/javascript';

import { parse } from 'mrz';

import Tesseract from 'tesseract.js';
import { createWorker, PSM, OEM } from 'tesseract.js';

import moment from 'moment';

//Initialize Tesseract
const worker = createWorker({
	langPath: '/tessdata/',
	//logger: m => console.log(m),
});

//console.log(PSM);
//console.log(OEM);

(async () => {
	await worker.load();
	//await worker.loadLanguage('mrz+osd');
	await worker.loadLanguage('mrz');
	await worker.initialize('mrz');
	await worker.setParameters({
		//tessedit_char_whitelist: '<ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
		tessedit_pageseg_mode: PSM.AUTO,  // 3 - Igual que con la línea de comandos
		//tessedit_ocr_engine_mode: OEM.DEFAULT,
		user_defined_dpi: '70',
	});
})();		

const styles = theme => ({
	spacer: {
		flex: "1 1 auto",
	},
	title: {
		flex: "1 1 auto",
	},
	paper: {
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			padding: theme.spacing.unit * 3,
		},
	},
	paperBottom: {
		padding: theme.spacing.unit * 2,
		[theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
			padding: theme.spacing.unit * 3,
		},
		marginBottom: theme.spacing.unit * 2,
	},
	stepper: {
		padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`,
	},
	tabsRoot: {
		//marginBottom: theme.spacing.unit * 1,
	},
	tabRoot: {
		//textTransform: "none",
	},
	snackbar: {
	},
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
    externalSelector: {
        display: "flex", 
        flexGrow: 1,
    },
   	hidden: {
		display: 'none',
	},
    hiddenSpaceReserved: {
        visibility: 'hidden',
    },
	toolbar: {
	},
	alignButton: {
		position: "absolute",
	},
	alignButton2: {
		top: "9px",
		position: "relative",
	},
	dashedBorder: {
		border: "dashed",
		borderColor: "#C8C8C8",
	},
	inputLabel: {
		fontSize: "12px",
		height: "35px",
		lineHeight: "35px",
	},
	selectMultipleIcon: {
		display: "none",
	},
	selectIcon: {
	},
	iframe: {
		width: '100%',
		minHeight: "300px",
		border: "none",
		// overflow: hidden - Es imposible que un iframe muestre algo flotante sobre otro (sería un problema de seguridad)
		// Otra cosa es que se coja el contenido del iframe y se inyecte en un div, con javascript.
	},
});

class EntityView extends Component {
	
	constructor(props) {
		super(props);
		// console.log(">> EntityView.constructor");
		
		this.state = {
			currentEntity: null,
			data: null,
			uploadedData: {},  // Object containing uploaded data (bytea) for each attribute of type "bytea".
			showBlockly: false,
            isListenerAdded: false,
		};

		this.uploadMrz = React.createRef();
		
		this.refreshData = this.refreshData.bind(this);
		this.handleGenerateCode = this.handleGenerateCode.bind(this);
		this.handleEditClick = this.handleEditClick.bind(this);
		this.handleCancelClick = this.handleCancelClick.bind(this);
		this.handleSaveClick = this.handleSaveClick.bind(this);
		this.handleMrzClick = this.handleMrzClick.bind(this);
		this.handleUploadMrzChange = this.handleUploadMrzChange.bind(this);
		this.save = this.save.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleChangeSelectedTab = this.handleChangeSelectedTab.bind(this);
		this.getVariableValue = this.getVariableValue.bind(this);
		this.handleDropDocument = this.handleDropDocument.bind(this);
		this.handleDeleteDocument = this.handleDeleteDocument.bind(this);
		this.handleAddChip = this.handleAddChip.bind(this);
		this.handleDeleteChip = this.handleDeleteChip.bind(this);
		this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
		this.handleColorChange = this.handleColorChange.bind(this);
		this.handleRichTextChange = this.handleRichTextChange.bind(this);
		this.handleIconChange = this.handleIconChange.bind(this);
		this.handleBpmnDiagramChange = this.handleBpmnDiagramChange.bind(this);
		this.getErrorMessage = this.getErrorMessage.bind(this);
		this.handleMessage = this.handleMessage.bind(this);
		this.handleExternalSearch = this.handleExternalSearch.bind(this);
		this.handleExternalClear = this.handleExternalClear.bind(this);
		this.handleActionClick = this.handleActionClick.bind(this);
		
		this.refreshDataThrottled = throttle(500, this.refreshData);
		
		if (this.props.context.state[this.props.entity] == null) {
			this.props.context.state[this.props.entity] = {};
		}
	}
	
	// Event handlers
	handleExternalClear(attribute) {
		this.setState((state, props) => {
			if (attribute.referenceAttributeName != null) {
				state.data[attribute.referenceAttributeName] = null;
			}
			else {
				state.data[attribute.name] = null;
			}
			return {
				data: state.data,
			};
		}, () => {
			if (this.inputRefs[attribute.name] != null && this.inputRefs[attribute.name].current != null) {
				this.inputRefs[attribute.name].current.value = "";
			}
		});
	}
	
	handleMessage(event) {

        console.log("Message received:");
		if (event && event.data && event.data.attribute) {
			const model = this.props.context.model;
			const entityModel = model.entities[this.props.entity];
			const attribute = Object.values(this.state.attributes).filter(attribute => attribute.name == event.data.attribute)[0];
            const entityLocalModel = localModel.entities[this.props.entity]
            const attributes = this.state.attributes;

            let url = attribute.externalSelectorUrl;
            this.props.context.variables.forEach(variable => { url = url.replaceAll("${variable." + variable.name + "}", variable.value)});

            attributes
                    .filter(attribute => (attribute.incomingReferenceAttributeName === undefined
                            && (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
                            && !attribute.computed
                            && !attribute.editableOnInsertOnly
                            && ((attribute.array && attribute.type === 'TEXT')
                                || attribute.color
                                || attribute.icon
                                || (!attribute.array && attribute.type === "POINT")
                                || (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "DOCUMENT")
                                || (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "BPMN_EDITOR")
                                || (this.inputRefs[attribute.name] != null 
                                    && this.inputRefs[attribute.name].current !== null 
                                    && (this.inputRefs[attribute.name].current.type === 'checkbox' 
                                        || this.inputRefs[attribute.name].current.value !== "")))))
                    .forEach(attribute => url = url.replaceAll("${attribute." + attribute.name + "}", this.getVariableValue(attribute)));
                        
            console.log("Origin: " + event.origin);
            console.log("URL: " + url);
            
            console.log("Event entity: " + event.data.entity + ", Current entity: " + this.props.entity);
            console.log("Event entity id: " + event.data.entityId + ", Current entity id: " + this.props.entityId);
            console.log("Event attribute: " + event.data.attribute);
            console.log("Event value: " + event.data.value);
            
			if ((url.startsWith(event.origin) || this.props.context.baseUrl.startsWith(event.origin))
					&& event.data.entity == this.props.entity
					//&& event.data.entityId == this.props.entityId   -> He relajado el requisito porque algunos partners no lo usan y les genera problemas. Si veo que afecta a la seguridad, volveré a recuperar este filtro y que cambien sus ventanas flotantes.
                ) {
                
                console.log("Accepting message...");
                
				this.setState((state, props) => {
					if (attribute.referenceAttributeName != null) {
						state.data[attribute.referenceAttributeName] = event.data.value;
					}
					else {
						state.data[attribute.name] = event.data.value;
					}
					return {
						data: state.data,
					};
				}, () => {
					if (this.inputRefs[attribute.name] != null && this.inputRefs[attribute.name].current != null) {
						if (attribute.referenceAttributeName == null) {
							this.inputRefs[attribute.name].current.value = (this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name]);
						}
						else {
							let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
							let selectedValue = (this.state.data == null || this.state.data[attribute.referenceAttributeName] == null ? null : this.state.data[attribute.referenceAttributeName] === null ? "" : this.getLabelLegacyText(this.state.data[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName]));
							this.inputRefs[attribute.name].current.value = (selectedValue == null ? "" : selectedValue);
						}
					}
                    console.log("Message accepted");
				});
			}
            else {
                console.log("Message discarded");
            }
		}
	}
	
	handleExternalSearch(attributeToSelect) {
		let url = attributeToSelect.externalSelectorUrl;
        this.props.context.variables.forEach(variable => { url = url.replaceAll("${variable." + variable.name + "}", variable.value)});
        
        const entityLocalModel = localModel.entities[this.props.entity]
        const attributes = this.state.attributes;
        attributes
                .filter(attribute => (attribute.incomingReferenceAttributeName === undefined
                        && (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
                        && !attribute.computed
                        && !attribute.editableOnInsertOnly
                        && ((attribute.array && attribute.type === 'TEXT')
                            || attribute.color
                            || attribute.icon
                            || (!attribute.array && attribute.type === "POINT")
                            || (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "DOCUMENT")
                            || (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "BPMN_EDITOR")
                            || (this.inputRefs[attribute.name] != null 
                                && this.inputRefs[attribute.name].current !== null 
                                && (this.inputRefs[attribute.name].current.type === 'checkbox' 
                                    || this.inputRefs[attribute.name].current.value !== "")))))
                .forEach(attribute => url = url.replaceAll("${attribute." + attribute.name + "}", this.getVariableValue(attribute)));
        
        let target = attributeToSelect.externalSelectorTarget;
		let windowFeatures = attributeToSelect.externalSelectorWindowFeatures;
		let parameters = "language=" + this.props.i18n.language 
				+ "&entity=" + this.props.entity 
				+ "&entityId=" + this.props.entityId 
				+ "&attribute=" + attributeToSelect.name 
				+ (attributeToSelect.addAccessToken ? "&access_token=" + this.props.context.accessToken: "");
		url += (url.indexOf("#") != (-1) ? "&" : "#") + parameters;
		window.open(url, (target == null || target == "" ? "_blank" : target), windowFeatures);
        
        if (this.state != null && !this.state.isListenerAdded) {
            window.addEventListener("message", this.handleMessage);
            this.setState({
                isListenerAdded: true,
            });
        }
	}
	
	go(target, resetTab, entity, startTimestamp, endTimestamp) {
		if (resetTab) {
			let entityLocalModel = localModel.entities[entity]
			let selectedTab = null;
			if (entityLocalModel.tabs != null) {
				let selectedTabItem = Object.values(entityLocalModel.tabs).filter(tab => Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id).length > 0).sort((a, b) => a.order - b.order)[0];
				if (selectedTabItem != null) {
					selectedTab = selectedTabItem.id;
				}
			}
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].selectedTab = selectedTab;
		}

		if (startTimestamp != null) {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].startTimestamp = startTimestamp;
		}
		else {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].startTimestamp = null;
		}
		
		if (endTimestamp != null) {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].endTimestamp = endTimestamp;
		}
		else {
			if (this.props.context.state[entity] == null) {
				this.props.context.state[entity] = {};
			}
			this.props.context.state[entity].endTimestamp = null;
		}
		
		history.push(target);
	}
	
	handleActionClick(event, item, attribute) {
		event.preventDefault();
		event.stopPropagation();
		if (item[attribute.name] != null && item[attribute.name] != "") {
			
			let url = item[attribute.name];
			url += (url.indexOf("?") != (-1) ? "&" : "?") + "access_token=" + this.props.context.accessToken;
			
			fetch(url, {
				method: "GET",
				redirect: "manual",
			})
			.then(response => {
				let location = response.headers.get("Location");
				
				if (location != null && location != "") {
					if (location.startsWith("http") || location.startsWith("https")) {
						document.location = location;
					}
					else {
						this.go(location);
					}
				}
				else {
					setTimeout(this.refreshDataThrottled(), 500);
				}
			})
			.catch(error => {
				console.log(error);
				setTimeout(this.refreshDataThrottled(), 500);
			});
		}
	}
	
	handleGenerateCode(event) {
		var code = BlocklyJS.workspaceToCode(this.simpleWorkspace.workspace);
		this.inputRefs["contents"].current.value = code;
	}
	
	handleIconChange(event, attribute) {
		this.setState((state, props) => {
			state.data[attribute.name] = event.target.value;
			return {
				data: state.data,
			};
		});
	}
	
	handleColorChange(event, attribute) {
		const model = this.props.context.model;
		this.setState((state, props) => {
			if (!(attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))) {
				state.data[attribute.name] = event.hex;
			}
			return {
				data: state.data,
			};
		});
	}

	handleRichTextChange(value, attribute) {
		// To simplify and not break compatibility, I assign value both ways (managed & unmanaged)
		this.setState((state, props) => {
			state.data[attribute.name] = value;
			return {
				data: state.data,
			};
		});
		if (this.inputRefs[attribute.name] != null) {
			this.inputRefs[attribute.name].current = { value: value };
		}
	}
	
	handleChangeSelectedTab(event, value) {
		this.props.context.state[this.props.entity].selectedTab = value;
		this.setState({
			// No se utiliza, pero sirve para forzar el refresco de la pantalla
			selectedTab: value,
		});
	}
	
	handleCheckboxChange(event, checked, attribute) {
		//console.log(">> EntityView.handleDeleteChip")
		this.setState((state, props) => {
			if (attribute.required) {
				state.data[attribute.name] = checked;
			}
			else {
				state.data[attribute.name] = (state.data[attribute.name] == null ? true : (state.data[attribute.name] ? false : null));
			}
			return {
				data: state.data,
			};
		});
	}
	
	handleBpmnDiagramChange(xml, attribute) {
		this.setState((state, props) => {
			state.data[attribute.name] = xml;
			
			//console.log(state.data);
			
			return {
				data: state.data,
			};
		});
	}
	
	handleAddChip(chip, attribute) {
		//console.log(">> EntityView.handleAddChip")
		if (attribute.pattern == null
				|| attribute.pattern == ""
				|| new RegExp(attribute.pattern).test(chip)) {
		
			this.setState((state, props) => {
				if (state.data == null) {
					state.data = [{}];
				}
				if (state.data[attribute.name] == null) {
					state.data[attribute.name] = [];
				}
				state.data[attribute.name].push(chip);
				return {
					data: state.data,
				};
			});
		}
		else {
			this.setState({
				message: this.props.t('invalidValue'),
				messageError: true,
				messageOpened: true,
			});
		}
	}
	
	handleDeleteChip(chip, index, attribute) {
		//console.log(">> EntityView.handleDeleteChip")
		this.setState((state, props) => {
			if (state.data[attribute.name] != null) {
				state.data[attribute.name].splice(index, 1);
			}
			return {
				data: state.data,
			};
		});
	}
	
	handleKeyDown(event) {
		if (event.keyCode === 27) {
			this.handleCancelClick(event);
		}
	}
	
	handleEditClick(event) {
		event.stopPropagation();
		history.replace("/admin/" + this.props.entity + '/' + this.props.entityId + "/edit");
	}
	
	handleCancelClick(event) {
		event.stopPropagation();
		
		const cancelUrl = getParameter("cancelUrl", document.location.search);
		
		if (cancelUrl != null) {
			document.location.replace(cancelUrl);
		}
		else {
			if (this.props.entityId !== undefined) {
				history.replace("/admin/" + this.props.entity + '/' + this.props.entityId + "/view");
			}
			else {
				history.goBack();
			}
		}
	}
	
	handleMrzClick(event) {
		this.uploadMrz.current.click();
	}
	
	handleUploadMrzChange(event) {
		event.stopPropagation();
		event.preventDefault();
		
		let files = event.target.files || event.dataTransfer.files;
		
		if (files != null && files.length > 0) {
		
			this.props.context.showActivityIndicator();
			
			(async () => {
				const { data: { text } } = await worker.recognize(files[0]);
				
				let text2 = text.trim().replaceAll(/[ \t]+/g, '');
				text2 = text2.split("\n");
				
				// MRZ TD-1 (3 lines of 30 characters)
				let mrz1 = text2.filter(line => line.match(/^[0-9A-Z<]{30}$/g));
				let result1 = null;
				if (mrz1.length >= 3) {
					result1 = parse(mrz1.slice(mrz1.length - 3, mrz1.length));
				}
				else {
					// MRZ TD-2 (2 lines of 36 characters)
					mrz1 = text2.filter(line => line.match(/^[0-9A-Z<]{36}$/g));
					if (mrz1.length >= 2) {
						result1 = parse(mrz1.slice(mrz1.length - 2, mrz1.length));
					}
					else {
						// MRZ TD-3 (2 lines of 44 characters)
						mrz1 = text2.filter(line => line.match(/^[0-9A-Z<]{44}$/g));
						if (mrz1.length >= 2) {
							result1 = parse(mrz1.slice(mrz1.length - 2, mrz1.length));
						}
					}
				}
				
				let error = false;
				
				if (result1 != null) {
					const model = this.props.context.model;
					const entityModel = model.entities[this.props.entity];
					const entityLocalModel = localModel.entities[this.props.entity];
					
					Object.values(entityModel.attributes)
							.filter(attribute => attribute.mrzField != null)
							.forEach(attribute => {
								if (this.inputRefs[attribute.name] != null && this.inputRefs[attribute.name].current != null) {
									let value = result1.fields[attribute.mrzField];
									if (value == null) {
										error = true;
									}
									if (attribute.type === "DATE") {
										let date = null;
										if (value != null) {
											let year1 = new Number("19" + value.substr(0, 2));
											let year2 = new Number("20" + value.substr(0, 2));
											let year = (year2 > new Date().getFullYear() ? year1 : year2);
											let month = value.substr(2, 2);
											let day = value.substr(4, 2);
											date = year + "-" + month + "-" + day;
										}
										this.inputRefs[attribute.name].current.value = (value == null ? "" : date);
									}
									else {
										this.inputRefs[attribute.name].current.value = (value == null ? "" : value);
									}
								}
							});
				}
				else {
					error = true;
				}
				
				if (error) {
					console.log(text2);
					console.log(mrz1);
					console.log(result1);
					this.setState({
						message: this.props.t('mrzError'),
						messageError: true,
						messageOpened: true,
					});
				}
				
				this.props.context.hideActivityIndicator();
			})();
		}
	}
	
	getVariableType(attribute) {
		const entityLocalModel = localModel.entities[this.props.entity];
		
		let type = "";
		type += (attribute.array ? "[" : "");
		if (attribute.enumType != null) {
			type += attribute.enumType.schema.substr(0, 1).toUpperCase()
					+ attribute.enumType.schema.substr(1)
					+ '_' 
					+ attribute.enumType.name.substr(0, 1).toUpperCase() 
					+ attribute.enumType.name.substr(1) 
					+ 'EnumType';
		}
		else {
			switch(attribute.type) {
				case "INTEGER":
				case "SMALLINT":
				case "BIGINT":
				case "SERIAL":
				case "SMALLSERIAL":
				case "BIGSERIAL":
					type += "Int";
					break;
				
				case "BOOLEAN":
					type += "Boolean";
					break;
				
				case "DECIMAL":
				case "DOUBLE_PRECISION":
				case "REAL":
				case "MONEY":
					type += "Float";
					break;
				
				default:
					if (entityLocalModel.attributes[attribute.name] != null
							&& entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
						type += "Models_DocumentTypeInputType";
					}
					else {
						type += "String";
					}
			}
		}
		
		type += (attribute.array ? "]" : "");
		type += (attribute.required ? "!" : "");
		return type;
	}
	
	getVariableValue(attribute) {
		//console.log(">>>> " + attribute.name);
		const entityLocalModel = localModel.entities[this.props.entity];
		
		if (attribute.externalSelectorUrl != null && !attribute.externalSelectorEditableValue) {
			if (attribute.referenceAttributeName != null) {
				let value = this.state.data[attribute.referenceAttributeName];
				return (value == null ? null : value.id);
			}
			else {
				return this.state.data[attribute.name];
			}
		}
		else {
			switch(attribute.type) {
				case "INTEGER":
				case "SMALLINT":
				case "BIGINT":
				case "SERIAL":
				case "SMALLSERIAL":
				case "BIGSERIAL":
					if (attribute.referenceAttributeName === undefined) {
						if (this.props.mode == 'view' || attribute.computed) {
							// Ojo, cuando se borra un documento en modo vista, las cajas de los números son las formateadas y, por lo tanto,
							// hay que coger el valor del estado para hacer el update.
							return this.state.data[attribute.name];
						}
						else {
							return this.inputRefs[attribute.name].current.valueAsNumber;
						}
					}
					else {
						if (this.inputRefs[attribute.name].current == null) {
							return null;
						}
						else {
							return (this.inputRefs[attribute.name].current.select.state.value == null ? null : this.inputRefs[attribute.name].current.select.state.value.value);
						}
					}
				
				case "BOOLEAN":
					return this.state.data[attribute.name];
					
				case "DECIMAL":
				case "DOUBLE_PRECISION":
				case "REAL":
				case "MONEY":
					if (this.props.mode == 'view' || attribute.computed) {
						// Ojo, cuando se borra un documento en modo vista, las cajas de los números son las formateadas y, por lo tanto,
						// hay que coger el valor del estado para hacer el update.
						return this.state.data[attribute.name];
					}
					else {
						return this.inputRefs[attribute.name].current.valueAsNumber;
					}
				
				case "BYTEA":
					const value = this.state.uploadedData[attribute.name];
					if (value == null) {
						return null;
					}
					else {
						if (attribute.array) {
							return value.map(item => JSON.stringify(item));
						}
						else {
							return JSON.stringify(value);
						}
					}
	
				case "POINT":
					if (isNaN(this.inputRefs[attribute.name + "Latitude"].current.valueAsNumber)
							|| isNaN(this.inputRefs[attribute.name + "Longitude"].current.valueAsNumber)) {
						return null;
					}
					else {
						return "(" + this.inputRefs[attribute.name + "Latitude"].current.valueAsNumber + "," + this.inputRefs[attribute.name + "Longitude"].current.valueAsNumber + ")";
					}
				
				default:
					if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
						if (attribute.array) {
							if (this.state.uploadedData[attribute.name] == null || this.state.uploadedData[attribute.name].length === 0) {
								return null;
							}
							else {
								return this.state.uploadedData[attribute.name];
							}
						}
						else {
							if (this.state.uploadedData[attribute.name] == null || this.state.uploadedData[attribute.name].length === 0) {
								return null;
							}
							else {
								return this.state.uploadedData[attribute.name][0];
							}
						}
					}
					else if (entityLocalModel.attributes[attribute.name].type == "BPMN_EDITOR") {
						return this.state.data[attribute.name];
					}
					else if (entityLocalModel.attributes[attribute.name].type == "SIGNATURE") {
						let signatureData = this.inputRefs[attribute.name].current.toData();
						if (signatureData != null && signatureData.length > 0) {
							return JSON.stringify(signatureData);
						}
						else {
							return null;
						}
					}
					else if (attribute.array 
							&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")
							&& attribute.enumType == null) {
						if (this.state.data != null
								&& this.state.data[attribute.name] != null
								&& this.state.data[attribute.name].length > 0) {
							return this.state.data[attribute.name];
						}
						else {
							return null;
						}
					}
					else if (attribute.array 
							&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR")
							&& attribute.enumType != null) {
						let value = Object.values(this.inputRefs[attribute.name].current.options).filter(option => option.selected).map(option => option.value);
						if (value != null && value.length == 0) {
							value = null;
						}
						return value;
					}
					else if (attribute.color) {
						return this.state.data[attribute.name];
					}
					else if (attribute.icon) {
						return this.state.data[attribute.name];
					}
					else {
						if (attribute.referenceAttributeName === undefined) {
                            if (attribute.computed) {
                                return this.state.data[attribute.name];
                            }
                            else {
                                return this.inputRefs[attribute.name].current.value;
                            }
						}
						else {
							return (this.inputRefs[attribute.name].current.select.state.value == null ? null : this.inputRefs[attribute.name].current.select.state.value.value);
						}
					}
			}
		}
	}
	
	handleSaveClick(event) {
		//console.log(">> EntityView.handleSaveClick")
		event.stopPropagation();
		event.preventDefault();
		this.save();
	}
	
	getErrorMessage(message) {
		let { errorMessages } = this.props.context;
		const { t } = this.props;
		
		const model = this.props.context.model;
		const entityModel = model.entities[this.props.entity];
		
		let hasMessage = false;
		
		for (var i = 0; i < errorMessages.length; i++) {
			let errorMessage = errorMessages[i];
			let name = errorMessage.name;
			let regularExpression = new RegExp(errorMessage.regularExpression);
			
			if (regularExpression.test(message)) {
				hasMessage = true;
				
				let label = t("errorMessages." + name);
				label = label.replaceAll("$schema", entityModel.schema);
				label = label.replaceAll("$entity", entityModel.name);
				
				let matches = message.match(regularExpression);
				for (var j = 1; j < matches.length; j++) {
					label = label.replaceAll("$" + j, matches[j]);
				}
				
				label = label.replace(/\$([0-9]+)/g, function(match, p1) { return matches[Number(p1)] }) 
				label = label.replace(/\$label\(\"([a-zA-Z0-9_.]+)\"\)/g, function(match, p1) { return t(p1) })
				
				return label;
			}
		}
		
		if (!hasMessage) {
			return message;
		}
	}
	
	save() {
		this.props.context.showActivityIndicator();
		
		const okUrl = getParameter("okUrl", document.location.search);
		
		const model = this.props.context.model;
		const attributes = this.state.attributes;
		
		//console.log("Attributes:");
		//console.log(attributes);
		const entityLocalModel = localModel.entities[this.props.entity];
		
		//console.log(this.props.entityId);
		
		if (this.props.entityId !== undefined) {
			const query = 
					'mutation Update($' + this.state.keyAttribute.name + ': ' + this.getVariableType(this.state.keyAttribute) + " "
							+ attributes
									.filter(attribute => attribute.incomingReferenceAttributeName === undefined
											&& (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
											&& !attribute.computed
                                            && !attribute.editableOnInsertOnly
											&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))

									.map(attribute => '$' 
											+ attribute.name 
											+ ': ' 
											+ this.getVariableType(attribute)
									)
									.join(" ") + ') { ' +
					'	result: ' + this.props.entity.replace(".", "_") + 'Update(' +
					'		where: {' +
					'			' + this.state.keyAttribute.name + ': {EQ: $' + this.state.keyAttribute.name + '}' +
					'		}' +
					'		entity: {' + 
					attributes
							.filter(attribute => attribute.incomingReferenceAttributeName === undefined
									&& (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
									&& !attribute.computed
                                    && !attribute.editableOnInsertOnly
									&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
							.map(attribute => attribute.name + ': $' + attribute.name).join(" ") +
					'		}' +
					'	) {' +
					'		' + this.state.keyAttribute.name + 
					'	}' +
					'}';
			
			//console.log("Query:");
			//console.log(query);
			
			const variables = {
	    		authorization: this.props.context.accessToken,
	    	};
			variables[this.state.keyAttribute.name] = parseInt(this.props.entityId);
			
			//console.log("InputRefs:");
			//console.log(this.inputRefs);
			
			attributes
					.filter(attribute => (attribute.incomingReferenceAttributeName === undefined
							&& (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
							&& !attribute.computed
                            && !attribute.editableOnInsertOnly
							&& ((attribute.array && attribute.type === 'TEXT')
								|| attribute.color
								|| attribute.icon
								|| (!attribute.array && attribute.type === "POINT")
								|| (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "DOCUMENT")
								|| (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "BPMN_EDITOR")
								|| (this.inputRefs[attribute.name] != null 
									&& this.inputRefs[attribute.name].current !== null 
									&& (this.inputRefs[attribute.name].current.type === 'checkbox' 
										|| this.inputRefs[attribute.name].current.value !== "")))))
					.forEach(attribute => variables[attribute.name] = this.getVariableValue(attribute));
			
			//console.log("Variables:");
			//console.log(JSON.stringify(variables));
			
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json != null
						&& json.errors != null
						&& json.errors[0] != null
                        && (json.errors[0].message == "SessionTimeout" 
                                || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
					document.location = '/login';
				}
				
				this.props.context.hideActivityIndicator();
				if (json.errors != null) {
					this.setState({
						message: this.getErrorMessage(json.errors[0].message),
						messageError: true,
						messageOpened: true,
					});
				}
				else {
                    this.setState({
                        message: this.props.t('updateSuccess'),
                        messageError: false,
                        messageOpened: true,
                    });
					if (this.props.entity == 'Models.Personalization') {
						refreshPersonalization(this.props.theme, this.props.context.baseUrl).then(result => {
							this.forceUpdate();
							if (okUrl != null) {
								document.location.replace(okUrl);
							}
							else {
								history.replace("/admin/" + this.props.entity + '/' + this.props.entityId + "/view");
							}
						});
					}
					else if (this.props.entity.startsWith("Models.")) {
						refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context, this.props.theme).then(result => {
							this.forceUpdate();
							if (okUrl != null) {
								document.location.replace(okUrl);
							}
							else {
								history.replace("/admin/" + this.props.entity + '/' + this.props.entityId + "/view");
							}
						});
					}
					else {
						if (okUrl != null) {
							document.location.replace(okUrl);
						}
						else {
							this.setState((state, props) => {
								let refresh = state.refresh;
								if (refresh == null) {
									refresh = 0;
								}
								return {
									refresh: refresh + 1
								};
							}, () => history.replace("/admin/" + this.props.entity + '/' + this.props.entityId + "/view"));
						}
					}
				}
			});
		}
		else {
			let attributesStr = attributes
					.filter(attribute => attribute.incomingReferenceAttributeName === undefined
							//&& !attribute.computed
							&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
					.map(attribute => '$' 
							+ attribute.name 
							+ ': ' 
							+ this.getVariableType(attribute)
					)
					.join(" ");
			
			const query = 
				'mutation Create' + (attributesStr !== '' ? '(' + attributesStr + ')' : '') + '{ ' +
				'	result: ' + this.props.entity.replace(".", "_") + 'Create(' +
				'		entity: {' + 
				attributes
						.filter(attribute => attribute.incomingReferenceAttributeName === undefined
								//&& !attribute.computed
								&& !(!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
						.map(attribute => attribute.name + ': $' + attribute.name).join(" ") +
				'		}' +
				'	) {' +
				'		' + this.state.keyAttribute.name +
				'	}' +
				'}';
			
			//console.log("Query:");
			//console.log(query);
			
			const variables = {
				authorization: this.props.context.accessToken,
			};
			
			//console.log("InputRefs:");
			//console.log(this.inputRefs);
			
			attributes
					.filter(attribute => (attribute.incomingReferenceAttributeName === undefined
							//&& !attribute.computed
							&& ((attribute.array && attribute.type === 'TEXT')
								|| attribute.color
								|| attribute.icon
								|| (!attribute.array && attribute.type === "POINT")
								|| (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "DOCUMENT")
								|| (entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].type == "BPMN_EDITOR")
								|| (this.inputRefs[attribute.name] != null 
									&& this.inputRefs[attribute.name].current !== null
									&& (this.inputRefs[attribute.name].current.type === 'checkbox' 
										|| this.inputRefs[attribute.name].current.value !== "")))))
					.forEach(attribute => variables[attribute.name] = this.getVariableValue(attribute));
			
			//console.log("Variables:");
			//console.log(JSON.stringify(variables));

			let request = JSON.stringify({query: query, variables: variables});

			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json != null
						&& json.errors != null
						&& json.errors[0] != null
                        && (json.errors[0].message == "SessionTimeout" 
                                || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
					document.location = '/login';
				}
				
				this.props.context.hideActivityIndicator();
				if (json.errors != null) {
					this.setState({
						message: this.getErrorMessage(json.errors[0].message),
						messageError: true,
						messageOpened: true,
					});
				}
				else {
                    this.setState({
                        message: this.props.t('insertSuccess'),
                        messageError: false,
                        messageOpened: true,
                    });
					if (this.props.entity == 'Models.Personalization') {
						refreshPersonalization(this.props.theme, this.props.context.baseUrl).then(result => {
							this.forceUpdate();
							if (okUrl != null) {
								document.location.replace(okUrl);
							}
							else {
								history.replace("/admin/" + this.props.entity + '/' + json.data.result[this.state.keyAttribute.name] + "/view");
							}
						});
					}
					else if (this.props.entity.startsWith("Models.")) {
						refreshModels(this.props.context.accessToken, this.props.context.username, this.props.context, this.props.theme).then(result => {
							this.forceUpdate();
							if (okUrl != null) {
								document.location.replace(okUrl);
							}
							else {
								history.replace("/admin/" + this.props.entity + '/' + json.data.result[this.state.keyAttribute.name] + "/view");
							}
						});
					}
					else {
						if (okUrl != null) {
							document.location.replace(okUrl);
						}
						else {
							history.replace("/admin/" + this.props.entity + '/' + json.data.result[this.state.keyAttribute.name] + "/view");
						}
					}
				}
			})
			.catch(error => {
				console.log(error);
			});
		}
	}
	
	handleDropDocument(file, attribute) {
		let thisInstance = this;
		
		this.props.context.showActivityIndicator();
		
		const entityLocalModel = localModel.entities[this.props.entity];
		
		let xhr = new XMLHttpRequest();
		
		xhr.upload.onprogress = event => {
			thisInstance.setState({
				message: Math.round(100 * event.loaded / event.total) + "%",
				messageError: false,
				messageOpened: true,
			});
		};
		
		xhr.onload = event => {
			let oid = Number.parseInt(xhr.responseText);
			
			if (file.size > 0 
					&& (file.name.endsWith(".jpg")
							|| file.name.endsWith(".jpeg")
							|| file.name.endsWith(".png")
							|| file.name.endsWith(".gif")
							|| file.name.endsWith(".bmp"))) {
				
				let reader = new FileReader();
				
				reader.onloadend = function(event) {
					var image = new Image();
					
					image.onload = function() {
						let thumbnail = null;
						
						let targetMaxWidth = 640;
						let targetMaxHeight = 360;
						
						let targetWidth = image.width;
						let targetHeight = image.height;
						
						let canvas = document.createElement("canvas");
						let context = canvas.getContext("2d");
						
						if (targetWidth > targetMaxWidth || targetHeight > targetMaxHeight) {
							let aspectRatio = targetWidth / targetHeight;
							
							if (targetWidth > targetMaxWidth) {
								targetWidth = targetMaxWidth;
								targetHeight = targetWidth / aspectRatio;
							}
							if (targetHeight > targetMaxHeight) {
								targetHeight = targetMaxHeight;
								targetWidth = targetHeight * aspectRatio;
							}
						}
						
						canvas.width = targetWidth;
						canvas.height = targetHeight;
						context.drawImage(image, 0, 0, targetWidth, targetHeight);
						thumbnail = canvas.toDataURL();
						
						thisInstance.setState((state, props) => {
							if (state.uploadedData[attribute.name] == null) {
								state.uploadedData[attribute.name] = [];
							}
							
							state.uploadedData[attribute.name].push({
								name: file.name,
								size: file.size,
								type: file.type,
								thumbnail: thumbnail,
								oid: oid,
								width: image.width,
								height: image.height,
								text: (entityLocalModel.attributes[attribute.name].textFilter ? "TBD" : null)
							});

							return {
								uploadedData: state.uploadedData,
							};
						}, () => thisInstance.props.context.hideActivityIndicator());
					}
					image.src = URL.createObjectURL(new Blob([reader.result], {type: file.type}));
				}
				reader.readAsArrayBuffer(file);
			}
			else {
				thisInstance.setState((state, props) => {
					if (state.uploadedData[attribute.name] == null) {
						state.uploadedData[attribute.name] = [];
					}
					
					state.uploadedData[attribute.name].push({
						name: file.name,
						size: file.size,
						type: file.type,
						oid: oid,
						text: (entityLocalModel.attributes[attribute.name].textFilter ? "TBD" : null)
					});
					
					return {
						uploadedData: state.uploadedData,
					};
				}, () =>  thisInstance.props.context.hideActivityIndicator());
			}
		};
		
		xhr.open("POST", this.props.context.baseUrl + "/document/" + this.props.entity.replace(".", "/") + "/" + this.props.entityId + "/" + attribute.name + "/contents?access_token=" + this.props.context.accessToken, true);
		xhr.send(file);
	}
	
	handleDeleteDocument(file, attribute) {
		/*
		if (attribute.array) {
			this.setState((state, props) => {
				// TODO Ideally, we should filter by name, but we have a problem storing name and .....
				state.uploadedData[attribute.name] = state.uploadedData[attribute.name].filter(item => item.size !== file.fileSize);
				if (state.uploadedData[attribute.name] != null && state.uploadedData[attribute.name].length === 0) {
					state.uploadedData[attribute.name] = null;
				}
				return {
					uploadedData: state.uploadedData,
				};

				console.log(this.state.uploadedData);
			}, () => this.save());
		}
		*/
		
		this.setState((state, props) => {
			let uploadedData = state.uploadedData;
			uploadedData[attribute.name] = [];
			return {
				uploadedData: uploadedData,
			};
		}, () => this.props.context.hideActivityIndicator());
	}
	
	// Life cycle methods
	
	componentDidMount() {
		// console.log(">> EntityView.componentDidMount");
		this._isMounted = true;
		this.refreshDataThrottled();
	}
	
	componentDidUpdate(prevProps) {
		// console.log(">> EntityView.componentDidUpdate");
		//window.scrollTo(0, 0);
		if (this.props.entity !== prevProps.entity 
				|| this.props.entityId !== prevProps.entityId
				|| this.props.mode !== prevProps.mode
				) {
			if (this.props.context.state[this.props.entity] == null) {
				this.props.context.state[this.props.entity] = {};
			}
			this.refreshDataThrottled();
		}
	}
	
	componentWillUnmount() {
		//console.log(">> EntityView.componentWillUnmount");
		this._isMounted = false;
	}
	
	// Other methods
	
	dateToString(x, y) {
		var z = {
			M: x.getMonth() + 1,
			d: x.getDate(),
			h: x.getHours(),
			m: x.getMinutes(),
			s: x.getSeconds()
		};
		y = y.replace(/(M+|d+|h+|m+|s+)/g, v => {
			return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
		});
		return y.replace(/(y+)/g, v => {
			return x.getFullYear().toString().slice(-v.length)
		});
	}	
	
	setQuotes(attribute, value) {
		const hasQuotes = (attribute.type === "TEXT"
			|| attribute.type === "DATE"
			|| attribute.type === "TIMESTAMP"
			|| attribute.type === "VARCHAR"
			|| attribute.type === "CHAR"
			|| attribute.type === "TIME"
			|| attribute.type === "INTERVAL"
			|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
			|| attribute.type === "TIME_WITH_TIME_ZONE"
			|| attribute.type === "POINT"
			|| attribute.type === "POLYGON"
		);
		return (hasQuotes ? '"' : '') + value + (hasQuotes ? '"' : '');
	}
	
	getLabelAttributesQueryString(entityModel, entityLocalModel, exceptionAttribute, depth) {
		let str = "";

		if (depth == null) {
			depth = 1;
		}
		
		Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.forEach(attribute => {
				if (exceptionAttribute == null || attribute.name != exceptionAttribute.name) {
					if (entityLocalModel.attributes[attribute.name].type == "DOCUMENT") {
						str += attribute.name + " { name thumbnail type }, ";
					}
					else {
						str += attribute.name + ", ";
					}
				}
			});
		
		if (depth < 5) {
			Object.values(entityModel.references)
				.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
				.forEach(reference => {
					const model = this.props.context.model;

					let subLabel = this.getLabelAttributesQueryString(model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName], null, depth + 1);
					
					if (subLabel === "") {
						subLabel = Object.values(model.entities[reference.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0].name + ", ";
					}
					
					str += reference.referenceAttributeName + "{ " 
							+ subLabel
							+ "}, ";
				});
		}
		
		return str;
	}
	
	getLabel(item, entityModel, entityLocalModel) {
		if (item == null || entityModel == null || entityLocalModel == null) {
			return null;
		}
		
		const model = this.props.context.model;
		
		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: (item[attribute.name] == null ? "" : (attribute.type == "CUSTOM_TYPE" && entityLocalModel.attributes[attribute.name].type == "DOCUMENT" && item[attribute.name] != null ? <img style={{verticalAlign: "middle", maxHeight: "32px", maxWidth: "64px", paddingRight: 10}} src={(item[attribute.name] == null ? "" : item[attribute.name].thumbnail)} key={entityLocalModel.attributes[attribute.name].order}></img> : <span style={{paddingRight: 10}} key={entityLocalModel.attributes[attribute.name].order}>{(entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' ? (entityLocalModel.attributes[attribute.name].step == null ? new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) : new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)})) : item[attribute.name]) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix)}</span>)),
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return <>{labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label)}</>;
	}
	
	getLabelLegacyText(item, entityModel, entityLocalModel) {
		if (item == null || entityModel == null || entityLocalModel == null) {
			return null;
		}
		
		const model = this.props.context.model;
		
		let labels = Object.values(entityModel.attributes)
			.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].label && (entityLocalModel.attributes[attribute.name].labelLanguage == null || entityLocalModel.attributes[attribute.name].labelLanguage == i18n.language))
			.map(attribute => {
				return {
					order: entityLocalModel.attributes[attribute.name].order,
					label: (item[attribute.name] == null ? "" : (attribute.type == "CUSTOM_TYPE" && entityLocalModel.attributes[attribute.name].type == "DOCUMENT" && item[attribute.name] != null ? item[attribute.name].name : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' ? (entityLocalModel.attributes[attribute.name].step == null ? new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) : new Number(item[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)})) : item[attribute.name]) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix))),
				}
			});
		
		Object.values(entityModel.references)
			.filter(reference => reference.referenceAttributeName != null && entityLocalModel.attributes[reference.referenceAttributeName] != null && entityLocalModel.attributes[reference.referenceAttributeName].label && (entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == null || entityLocalModel.attributes[reference.referenceAttributeName].labelLanguage == i18n.language))
			.forEach(reference => {
				labels.push({
					order: entityLocalModel.attributes[reference.referenceAttributeName].order,
					label: this.getLabel(item[reference.referenceAttributeName], model.entities[reference.referencedKey.entityName], localModel.entities[reference.referencedKey.entityName])
				});
			});
		
		return labels.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).filter(label => label != null && label.label != null).map(label => label.label).join(", ");
	}

	setValues(attributes) {
		const model = this.props.context.model;
		const entityLocalModel = localModel.entities[this.props.entity];
		
		attributes
				.filter(attribute => attribute.incomingReferenceAttributeName === undefined)
				.forEach(attribute => {
			if (attribute.externalSelectorUrl != null) {
				if (attribute.referenceAttributeName == null) {
					this.inputRefs[attribute.name].current.value = (this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name]);
				}
				else {
					let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
					let selectedValue = (this.state.data == null || this.state.data[attribute.referenceAttributeName] == null ? null : this.state.data[attribute.referenceAttributeName] === null ? "" : this.getLabelLegacyText(this.state.data[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName]));
					this.inputRefs[attribute.name].current.value = (selectedValue == null ? "" : selectedValue);
				}
			}
			else {
				if (this.inputRefs[attribute.name] != null && this.inputRefs[attribute.name].current != null) {
					
					if (attribute.referenceAttributeName == null && entityLocalModel.attributes[attribute.name].type == "SIGNATURE") {
						if (this.state.data[attribute.name] != null) {
							this.inputRefs[attribute.name].current.fromData(JSON.parse(this.state.data[attribute.name]));
						}
						if (this.props.mode === "edit") {
							this.inputRefs[attribute.name].current.on();
						}
						else {
							this.inputRefs[attribute.name].current.off();
						}
					}
					else if (attribute.array && (attribute.type === "TEXT"
							|| attribute.type === "CHAR"
							|| attribute.type === "VARCHAR") && attribute.enumType === undefined) {
						// No tengo que asignar nada porque el componente ChipInput se utiliza en modo "controlled"
					}
					else if (!attribute.array 
							&& attribute.type === "BOOLEAN") {
						// No tengo que asignar nada porque el componente Checkbox se utiliza en modo "controlled"
					}
					else if (!attribute.array
							&& attribute.referenceAttributeName !== undefined) {
						let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
						let selectedValue = (this.state.data == null || this.state.data[attribute.referenceAttributeName] == null ? null : {
							value: this.state.data[attribute.referenceAttributeName][subKeyAttribute.name], 
							label: this.state.data[attribute.referenceAttributeName] === null ? "" : this.getLabel(this.state.data[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
						});
						if (this.inputRefs[attribute.name].current.select.select != null) {
							this.inputRefs[attribute.name].current.select.select.setValue(selectedValue, 'select-option');
						}
					}
					else if (attribute.array
							&& attribute.enumType != null) {
						Object.values(this.inputRefs[attribute.name].current.options).forEach(option => option.selected = this.state.data[attribute.name] != null && this.state.data[attribute.name].filter(item => item == option.value).length > 0);
					}
					else if (attribute.type === "TIMESTAMP"
							|| attribute.type === "TIMESTAMP_WITH_TIME_ZONE"
							|| attribute.type === "TIME"
							|| attribute.type === "TIME_WITH_TIME_ZONE") {
						this.inputRefs[attribute.name].current.value = (this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name].split(".")[0]);
					}
					else if (!attribute.array 
							&& (attribute.type === "INTEGER"
								|| attribute.type === "SMALLINT"
								|| attribute.type === "BIGINT"
								|| attribute.type === "SERIAL"
								|| attribute.type === "DECIMAL"
								|| attribute.type === "DOUBLE_PRECISION"
								|| attribute.type === "REAL"
								|| attribute.type === "MONEY"
								|| attribute.type === "SMALLSERIAL"
								|| attribute.type === "BIGSERIAL") 
							&& (attribute.computed 
									|| this.props.mode === 'view' 
									|| (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) 
											|| (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))) {
						this.inputRefs[attribute.name].current.value = (entityLocalModel.attributes[attribute.name].step == null 
								? (this.state.data[attribute.name] == null ? "" : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + new Number(this.state.data[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator }) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix))
								: (this.state.data[attribute.name] == null ? "" : (entityLocalModel.attributes[attribute.name].prefix == null ? "" : entityLocalModel.attributes[attribute.name].prefix) + new Number(this.state.data[attribute.name]).toLocaleString(navigator.language.replace("es", "de"), { useGrouping: !entityLocalModel.attributes[attribute.name].disableThousandsSeparator, minimumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length), maximumFractionDigits: (Math.floor(entityLocalModel.attributes[attribute.name].step) == entityLocalModel.attributes[attribute.name].step ? 0 : entityLocalModel.attributes[attribute.name].step.toString().split(".")[1].length)}) + (entityLocalModel.attributes[attribute.name].suffix == null ? "" : entityLocalModel.attributes[attribute.name].suffix)));
					}
					else {
						this.inputRefs[attribute.name].current.value = (this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name]);
					}
				}
				
				if (!attribute.array
						&& attribute.type === "POINT") {
					if (this.inputRefs[attribute.name + "Latitude"] != null && this.inputRefs[attribute.name + "Latitude"].current != null) {
						this.inputRefs[attribute.name + "Latitude"].current.value = (this.state.data[attribute.name] == null ? "" : String(Number(Number(this.state.data[attribute.name].substr(1, this.state.data[attribute.name].length - 2).split(",")[0]).toFixed(12))));
					}
					if (this.inputRefs[attribute.name + "Longitude"] != null && this.inputRefs[attribute.name + "Longitude"].current != null) {
						this.inputRefs[attribute.name + "Longitude"].current.value = (this.state.data[attribute.name] == null ? "" : String(Number(Number(this.state.data[attribute.name].substr(1, this.state.data[attribute.name].length - 2).split(",")[1]).toFixed(12))));
					}
				}
				
				if (!attribute.array && (attribute.type === "TEXT"
							|| attribute.type === "CHAR"
							|| attribute.type === "VARCHAR") && attribute.enumType === undefined && attribute.rich) {
					if (this.inputRefs[attribute.name] != null) {
						this.inputRefs[attribute.name].current = { value: this.state.data[attribute.name] };
					}
				}
			}
		});
	}
	
	refreshData() {
		try {
			//console.log(">> EntityView.refreshData")
			this.props.context.showActivityIndicator();
			
			const model = this.props.context.model;
			const entityModel = model.entities[this.props.entity];
			const entityLocalModel = localModel.entities[this.props.entity];
			
			let variantAttributes = [];
			
			let attributes = Object.values(entityModel.attributes)
					.filter(attribute => model.super || attribute.privileges["SELECT"] !== undefined)
					.map(attribute => {
						if (entityLocalModel.attributes[attribute.name] != null) {
							attribute["hidden"] = entityLocalModel.attributes[attribute.name].hidden;
							attribute["multiline"] = entityLocalModel.attributes[attribute.name].multiline;
							attribute["action"] = entityLocalModel.attributes[attribute.name].action;
							attribute["actionVisibleInForm"] = entityLocalModel.attributes[attribute.name].actionVisibleInForm;
							attribute["rich"] = entityLocalModel.attributes[attribute.name].rich;
                            attribute["spaceReserved"] = entityLocalModel.attributes[attribute.name].spaceReserved;
							attribute["xs"] = entityLocalModel.attributes[attribute.name].xs;
							attribute["variantSelector"] = entityLocalModel.attributes[attribute.name].variantSelector;
							attribute["variants"] = entityLocalModel.attributes[attribute.name].variants;
							attribute["sm"] = entityLocalModel.attributes[attribute.name].sm;
							attribute["min"] = entityLocalModel.attributes[attribute.name].min;
							attribute["max"] = entityLocalModel.attributes[attribute.name].max;
							attribute["step"] = entityLocalModel.attributes[attribute.name].step;
							attribute["length"] = entityLocalModel.attributes[attribute.name].length;
							attribute["pattern"] = entityLocalModel.attributes[attribute.name].pattern;
							attribute["password"] = entityLocalModel.attributes[attribute.name].password;
							attribute["maxFiles"] = entityLocalModel.attributes[attribute.name].maxFiles;
							attribute["computed"] = entityLocalModel.attributes[attribute.name].computed;
                            attribute["editableOnInsertOnly"] = entityLocalModel.attributes[attribute.name].editableOnInsertOnly;
							attribute["icon"] = entityLocalModel.attributes[attribute.name].icon;
							attribute["color"] = entityLocalModel.attributes[attribute.name].color;
							attribute["acceptedFileTypes"] = entityLocalModel.attributes[attribute.name].acceptedFileTypes;
							attribute["browseZip"] = entityLocalModel.attributes[attribute.name].browseZip;
							attribute["barcodeType"] = entityLocalModel.attributes[attribute.name].barcodeType;
							attribute["mrzField"] = entityLocalModel.attributes[attribute.name].mrzField;
							attribute["externalSelectorUrl"] = entityLocalModel.attributes[attribute.name].externalSelectorUrl;
                            attribute["externalSelectorEditableValue"] = entityLocalModel.attributes[attribute.name].externalSelectorEditableValue;
							attribute["externalSelectorTarget"] = entityLocalModel.attributes[attribute.name].externalSelectorTarget;
							attribute["externalSelectorWindowFeatures"] = entityLocalModel.attributes[attribute.name].externalSelectorWindowFeatures;
							attribute["addAccessToken"] = entityLocalModel.attributes[attribute.name].addAccessToken;
							
							if (entityLocalModel.attributes[attribute.name].variantSelector) {
								variantAttributes.push(attribute);
							}
						}
						return attribute;
					});
			
			Object.values(entityModel.references)
					.filter(reference => { 
						return  entityModel.attributes[reference.name] != null
								&& (entityLocalModel.attributes[reference.referenceAttributeName] === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].visible === undefined
								|| entityLocalModel.attributes[reference.referenceAttributeName].visible);
					})
					.forEach(reference => {
						attributes.push({
							name: reference.name,
							entityName: reference.entityName,
							referenceAttributeName: reference.referenceAttributeName,
							attributes: reference.attributes,
							referencedKey: reference.referencedKey,
							
							privileges: entityModel.attributes[reference.name].privileges,
							type: entityModel.attributes[reference.name].type,
							required: entityModel.attributes[reference.name].required,
							array: entityModel.attributes[reference.name].array,
							variants: entityLocalModel.attributes[reference.referenceAttributeName].variants,
							externalSelectorUrl: entityLocalModel.attributes[reference.name].externalSelectorUrl,
                            externalSelectorEditableValue: entityLocalModel.attributes[reference.name].externalSelectorEditableValue,
							externalSelectorTarget: entityLocalModel.attributes[reference.name].externalSelectorTarget,
							externalSelectorWindowFeatures: entityLocalModel.attributes[reference.name].externalSelectorWindowFeatures,
							addAccessToken: entityLocalModel.attributes[reference.name].addAccessToken,
						});
					});
			
			Object.values(model.entities)
					.forEach(entity => Object.values(entity.references)
							.filter(reference => reference.referencedKey.entityName === this.props.entity)
							.forEach(reference => {
								attributes.push({
									name: reference.name,
									entityName: reference.entityName,
									incomingReferenceAttributeName: reference.incomingReferenceAttributeName,
									attributes: reference.attributes,
									referencedKey: reference.referencedKey,
									variants: (entityLocalModel.attributes[reference.incomingReferenceAttributeName] != null ? entityLocalModel.attributes[reference.incomingReferenceAttributeName].variants : null),
								});
							}
					)
			);
			
			attributes.sort((a, b) => 
				(	
					entityLocalModel.attributes[a.incomingReferenceAttributeName || a.referenceAttributeName || a.name] === undefined 
						|| entityLocalModel.attributes[a.incomingReferenceAttributeName || a.referenceAttributeName || a.name].order == null 
							? Infinity 
							: entityLocalModel.attributes[a.incomingReferenceAttributeName || a.referenceAttributeName || a.name].order
				) - (
					entityLocalModel.attributes[b.incomingReferenceAttributeName || b.referenceAttributeName || b.name] === undefined 
						|| entityLocalModel.attributes[b.incomingReferenceAttributeName || b.referenceAttributeName || b.name].order == null 
							? Infinity 
							: entityLocalModel.attributes[b.incomingReferenceAttributeName || b.referenceAttributeName || b.name].order
				)
			);
			
			attributes = attributes
					.filter(attribute => 
							!entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							]
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].visible === undefined
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].visible
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].label
					);
			
			//console.log("Attributes:");
			//console.log(attributes);
			
			if (attributes != null && attributes.length > 0) {
				for (let i = 0; i < attributes.length; i++) {
					if (this.props.preselectedAttribute == null || attributes[i].name != this.props.preselectedAttribute) {
						attributes[i]["_autoFocus"] = true;
						break;
					}
				}
			}
			
			if (this.inputRefs == null) {
				this.inputRefs = {};
			}
			attributes
					.filter(attribute => attribute.incomingReferenceAttributeName === undefined)
					.forEach(attribute => {
	
				if (attribute.type == "POINT") {
					if (this.inputRefs[attribute.name + "Latitude"] == null) {
						this.inputRefs[attribute.name + "Latitude"] = React.createRef();
						this.inputRefs[attribute.name + "Longitude"] = React.createRef();
					}
				}
				else {
					if (this.inputRefs[attribute.name] == null) {
						this.inputRefs[attribute.name] = React.createRef();
					}
				}
			});
			
			//console.log("InputRefs:");
			//console.log(this.inputRefs);
			
			let selectedTab = null;
			if (entityLocalModel.tabs != null) {
				let selectedTabItem = Object.values(entityLocalModel.tabs).filter(tab => Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id).length > 0).sort((a, b) => a.order - b.order)[0];
				if (selectedTabItem != null) {
					selectedTab = selectedTabItem.id;
				}
			}
			if (this.props.context.state[this.props.entity].selectedTab == null) {
				this.props.context.state[this.props.entity].selectedTab = selectedTab;
			}
			
			let keyAttribute = Object.values(entityModel.keys).filter(key => key.primaryKey)[0].attributes[0];
			/*
			console.log(">>>");
			console.log(selectedTabMap);
			*/
			
			if (this.props.entityId !== undefined) {
				const query = 
						'{ ' +
						'	result:' + this.props.entity.replace(".", "_") + 'List(' +
						'		limit: 1' +
						'       where: {' + keyAttribute.name + ': {EQ: ' + this.setQuotes(keyAttribute, this.props.entityId) + '}}' +
						'	) {' + keyAttribute.name + ' ' +
						'   	' + attributes.map(attribute => {
	
								if (attribute.referenceAttributeName !== undefined) {
									let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
									return attribute.referenceAttributeName + "{ " + subKeyAttribute.name + ", " 
											+ (entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes != null ? entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes.join(', ') + ', ' : '')
											+ this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) 
											+ "}";
								}
								else if (attribute.incomingReferenceAttributeName === undefined
										&& attribute.customType == null) {
									return attribute.name;
								}
								else if (attribute.customType != null
										&& attribute.customType.schema == 'Models'
										&& attribute.customType.name == 'documentType') {
									return attribute.name + "{ name size type search thumbnail width height }";
								}
								return null;
							}).join(" ") + 
						'	} ' +
						'}';
			
				
				// Refiltering to remove label attributes that are not visible
				attributes = attributes
					.filter(attribute => 
							!entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							]
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].visible === undefined
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].visible
					);
				
				//console.log("Query:");
				//console.log(query);
				
				const variables = {
		    		authorization: this.props.context.accessToken
		    	};
				let request = JSON.stringify({query: query, variables: variables});
				fetch(this.props.context.baseUrl + "/graphql", {
					method: "POST",
					body: request
				})
				.then(response => response.json())
				.then(json => {
					if (json != null
							&& json.errors != null
							&& json.errors[0] != null
                            && (json.errors[0].message == "SessionTimeout" 
                                    || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
						document.location = '/login';
					}
					
					if (this._isMounted) {
						//console.log("Data result:");
						//console.log(json);
						
						//let selectedVariants = variantAttributes.filter(variantAttribute => json.data["result"][0][variantAttribute.name] != null).map(variantAttribute => json.data["result"][0][variantAttribute.name]);
						let selectedVariants = [];
						// Add selected variants
						variantAttributes.forEach(variantAttribute => {
							if (json.data["result"][0][variantAttribute.name] != null) {
								if (variantAttribute.array) {
									selectedVariants = selectedVariants.concat(json.data["result"][0][variantAttribute.name]);
								}
								else {
									selectedVariants.push(json.data["result"][0][variantAttribute.name]);
								}
							}
						});
						
						// Remove selected variants of invisible fields
						let variantsToRemove = [];
						variantAttributes.forEach(variantAttribute => {
							if (variantAttribute.variants != null && variantAttribute.variants.length > 0 && !variantAttribute.variants.some(element => selectedVariants.includes(element))) {
								if (json.data["result"][0][variantAttribute.name] != null) {
									if (variantAttribute.array) {
										variantsToRemove = variantsToRemove.concat(json.data["result"][0][variantAttribute.name]);
									}
									else {
										variantsToRemove.push(json.data["result"][0][variantAttribute.name]);
									}
								}
							}
						});
						selectedVariants = selectedVariants.filter(element => !variantsToRemove.includes(element));
						
						//console.log("Selected variants: " + selectedVariants);
												
						let state = {
								keyAttribute: keyAttribute,
								currentEntity: this.props.entity,
								data: json.data["result"][0],
								inputRefs: this.inputRefs,
								attributes: attributes,
								variantAttributes: variantAttributes,
								variants: selectedVariants, 
								uploadedData: {},
								questions: undefined,
							};
						
						attributes
								.filter(attribute => attribute.incomingReferenceAttributeName === undefined
										&& attribute.referenceAttributeName === undefined
										&& entityLocalModel.attributes[attribute.name] != null
										&& entityLocalModel.attributes[attribute.name].type == "DOCUMENT")
								.forEach(attribute => {
									state.uploadedData[attribute.name] = [];
									if (attribute.array) {
										if (state.data[attribute.name] != null) {
											state.uploadedData[attribute.name] = state.data[attribute.name];
										}
									}
									else {
										if (state.data[attribute.name] != null) {
											state.uploadedData[attribute.name].push(state.data[attribute.name]);
										}
									}
								});
						
						let data = state.data;
						
						if (this.props.context.state[this.props.entity].startTimestamp != null) {
							let startTimestamp = this.props.context.state[this.props.entity].startTimestamp;
							if (Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventStart).length > 0) {
								let eventStartAttribute = Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventStart)[0];
								if (eventStartAttribute.type == "DATE") {
									data[eventStartAttribute.name] = moment(startTimestamp).format('YYYY-MM-DD');
								}
								else if (eventStartAttribute.type == "TIMESTAMP") {
									data[eventStartAttribute.name] = moment(startTimestamp).format('YYYY-MM-DD HH:mm:ss');
								}
							}
							this.props.context.state[this.props.entity].startTimestamp = null;
						}
						
						if (this.props.context.state[this.props.entity].endTimestamp != null) {
							let endTimestamp = this.props.context.state[this.props.entity].endTimestamp;
							if (Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventEnd).length > 0) {
								let eventEndAttribute = Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventEnd)[0];
								if (eventEndAttribute.type == "DATE") {
									data[eventEndAttribute.name] = moment(endTimestamp).format('YYYY-MM-DD');
								}
								else if (eventEndAttribute.type == "TIMESTAMP") {
									data[eventEndAttribute.name] = moment(endTimestamp).format('YYYY-MM-DD HH:mm:ss');
								}
							}
							this.props.context.state[this.props.entity].endTimestamp = null;
						}
						
						this.setState(state, () => {
							this.setValues(attributes);
							this.props.context.hideActivityIndicator();
						});
						
						if (entityLocalModel.questions != null) {
							Object.values(entityLocalModel.questions).filter(question => question.visible).forEach(question => {
								
								const questionQuery = '{QuestionURL(id: ' + question.id + (question.useIds ? ' idParameter: ' + this.props.entityId: '') + ')}';
								
								const questionQueryVariables = {
						    		authorization: this.props.context.accessToken
						    	};
								let request = JSON.stringify({query: questionQuery, variables: questionQueryVariables});
								
								fetch(this.props.context.baseUrl + "/graphql", {
									method: "POST",
									body: request
								})
								.then(response => response.json())
								.then(json => {
									if (json.errors != null && json.errors.length > 0) {
										this.setState({
											message: this.getErrorMessage(json.errors[0].message),
											messageError: true,
											messageOpened: true,
										});
									}
									else {
										this.setState((state, props) => {
											let questions = state.questions;
											if (questions == null) {
												questions = {};
											}
											questions[question.name] = {
												url: json.data.QuestionURL
											};
											return {
												questions: questions,
											};
										});
									}
								});
							});
						}
					}
				})
				.catch(error => {
					console.log("!!!!! Retrying...");
					console.log(error);
					console.log(request);
					setTimeout(this.refreshDataThrottled(), 500);
				});
			}
			else {
				let data = {};
				
				attributes.filter(attribute => !attribute.array && attribute.type === 'BOOLEAN')
						.forEach(attribute => data[attribute.name] = false);
				
				// Refiltering to remove label attributes that are not visible
				attributes = attributes
					.filter(attribute => 
							!entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							]
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].visible === undefined
							|| entityLocalModel.attributes[
									attribute.incomingReferenceAttributeName 
									|| attribute.referenceAttributeName 
									|| attribute.name
							].visible
					);
				
				//console.log("!!!!!!");
				//console.log(entityLocalModel);
				//console.log(attributes);
			
                // Default values function
                let defaultValues = entityLocalModel.defaultValues;
                if (defaultValues != null) {
                    fetch(this.props.context.baseUrl + "/functions/" + defaultValues.schema + "." + defaultValues.name, {
                        method: "GET",
                        headers: {
                            "Authorization": "Bearer " + this.props.context.accessToken
                        }
                    })
                    .then(response => response.json())
                    .then(jsonResult => {
                        let result = JSON.parse(jsonResult[0]["result"]);
                        
                        //console.log(result);
                        // Default values
                        attributes.filter(attribute => result[attribute.name] != null)
                                .forEach(attribute => {
                                    data[attribute.name] = (!attribute.array 
                                        ? (result[attribute.name] == 'now()' 
                                            ? (attribute.type == 'TIME' 
                                                ? this.dateToString(new Date(), 'hh:mm') 
                                                : (attribute.type == 'TIMESTAMP' 
                                                    ? this.dateToString(new Date(), 'yyyy-MM-ddThh:mm:ss') 
                                                    : this.dateToString(new Date(), 'yyyy-MM-dd')
                                                )
                                            ) 
                                            : (attribute.type == 'BOOLEAN'
                                                ? (result[attribute.name].trim().toLowerCase() == 'true' ? true : (result[attribute.name].trim().toLowerCase() == 'null' ? null : false))
                                                : (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' 
                                                    ? Number(result[attribute.name])
                                                    : result[attribute.name].trim()
                                                )
                                            )
                                        ) 
                                        : result[attribute.name].split(",").map(value => value.trim())
                                    );

                                    
                                    this.setState((state, props) => {
                                        state.data[attribute.name] = data[attribute.name];
                                        return {
                                            data: state.data,
                                        };
                                    }, () => {
                                        this.setValues(attributes);
                                    });
                                                                        
                                    if (attribute.referenceAttributeName !== undefined) {
                                        let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
                                        const query = 
                                            '{ ' +
                                            '   result:' + attribute.referencedKey.entityName.replace(".", "_") + 'List(' +
                                            '       limit: 1' +
                                            '       where: {' + subKeyAttribute.name + ': {EQ: ' + data[attribute.name] + '}}' +
                                            '   ) {' + subKeyAttribute.name + ', ' 
                                                    + (entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes != null ? entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes.join(', ') + ', ' : '')
                                                    + this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) +
                                            '   } ' +
                                            '}';
                                        
                                        const variables = {
                                            authorization: this.props.context.accessToken
                                        };
                                        let request = JSON.stringify({query: query, variables: variables});
                                        fetch(this.props.context.baseUrl + "/graphql", {
                                            method: "POST",
                                            body: request
                                        })
                                        .then(response => response.json())
                                        .then(json => {
                                            if (json != null
                                                    && json.errors != null
                                                    && json.errors[0] != null
                                                    && (json.errors[0].message == "SessionTimeout" 
                                                            || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
                                                document.location = '/login';
                                            }
                                            this.setState((state, props) => {
                                                state.data[attribute.referenceAttributeName] = json.data["result"][0];
                                                return {
                                                    data: state.data,
                                                };
                                            }, () => {
                                                this.setValues(attributes);
                                            });
                                        });
                                    }
                                });
                    })
                    .catch(error => {
                      console.error("There was a problem with the fetch operation:", error);
                    });                    
                    
                }
                else {
    				// Default values
    				attributes.filter(attribute => entityLocalModel.attributes[attribute.name] != null && entityLocalModel.attributes[attribute.name].defaultValue != null)
    						.forEach(attribute => {
    							data[attribute.name] = (!attribute.array 
    								? (entityLocalModel.attributes[attribute.name].defaultValue.trim() == 'now()' 
    									? (attribute.type == 'TIME' 
    										? this.dateToString(new Date(), 'hh:mm') 
    										: (attribute.type == 'TIMESTAMP' 
    											? this.dateToString(new Date(), 'yyyy-MM-ddThh:mm:ss') 
    											: this.dateToString(new Date(), 'yyyy-MM-dd')
    										)
    									) 
    									: (attribute.type == 'BOOLEAN'
    										? (entityLocalModel.attributes[attribute.name].defaultValue.trim().toLowerCase() == 'true' ? true : (entityLocalModel.attributes[attribute.name].defaultValue.trim().toLowerCase() == 'null' ? null : false))
    										: (attribute.type === 'INTEGER' || attribute.type == 'SERIAL' || attribute.type == 'SMALLINT' || attribute.type == 'BIGINT' || attribute.type == 'SMALLSERIAL' || attribute.type == 'BIGSERIAL' || attribute.type == 'DECIMAL' || attribute.type == 'MONEY' || attribute.type == 'DOUBLE_PRECISION' || attribute.type == 'REAL' 
                                                ? Number(entityLocalModel.attributes[attribute.name].defaultValue.trim())
                                                : entityLocalModel.attributes[attribute.name].defaultValue.trim()
                                            )
    									)
    								) 
    								: entityLocalModel.attributes[attribute.name].defaultValue.split(",").map(value => value.trim())
    							);
    							if (attribute.referenceAttributeName !== undefined) {
    								let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
    								const query = 
    									'{ ' +
    									'	result:' + attribute.referencedKey.entityName.replace(".", "_") + 'List(' +
    									'		limit: 1' +
    									'       where: {' + subKeyAttribute.name + ': {EQ: ' + data[attribute.name] + '}}' +
    									'	) {' + subKeyAttribute.name + ', ' 
    											+ (entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes != null ? entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes.join(', ') + ', ' : '')
    											+ this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) +
    									'	} ' +
    									'}';
    								
    								const variables = {
    						    		authorization: this.props.context.accessToken
    						    	};
    								let request = JSON.stringify({query: query, variables: variables});
    								fetch(this.props.context.baseUrl + "/graphql", {
    									method: "POST",
    									body: request
    								})
    								.then(response => response.json())
    								.then(json => {
    									if (json != null
    											&& json.errors != null
    											&& json.errors[0] != null
                                                && (json.errors[0].message == "SessionTimeout" 
                                                        || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
    										document.location = '/login';
    									}
    									this.setState((state, props) => {
    										state.data[attribute.referenceAttributeName] = json.data["result"][0];
    										return {
    											data: state.data,
    										};
    									}, () => {
    										this.setValues(attributes);
    									});
    								});
    							}
    						});
                }
				
				if (this.props.context.state[this.props.entity].startTimestamp != null) {
					let startTimestamp = this.props.context.state[this.props.entity].startTimestamp;
					if (Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventStart).length > 0) {
						let eventStartAttribute = Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventStart)[0];
						if (eventStartAttribute.type == "DATE") {
							data[eventStartAttribute.name] = moment(startTimestamp).format('YYYY-MM-DD');
						}
						else if (eventStartAttribute.type == "TIMESTAMP") {
							data[eventStartAttribute.name] = moment(startTimestamp).format('YYYY-MM-DD HH:mm:ss');
						}
					}
					this.props.context.state[this.props.entity].startTimestamp = null;
				}
				
				if (this.props.context.state[this.props.entity].endTimestamp != null) {
					let endTimestamp = this.props.context.state[this.props.entity].endTimestamp;
					if (Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventEnd).length > 0) {
						let eventEndAttribute = Object.values(entityLocalModel.attributes).filter(attribute => attribute.eventEnd)[0];
						if (eventEndAttribute.type == "DATE") {
							data[eventEndAttribute.name] = moment(endTimestamp).format('YYYY-MM-DD');
						}
						else if (eventEndAttribute.type == "TIMESTAMP") {
							data[eventEndAttribute.name] = moment(endTimestamp).format('YYYY-MM-DD HH:mm:ss');
						}
					}
					this.props.context.state[this.props.entity].endTimestamp = null;
				}
				
				if (this.props.preselectedAttribute != null
						&& attributes.filter(attribute => attribute.referenceAttributeName != null && attribute.name == this.props.preselectedAttribute).length > 0) {
					let attribute = attributes.filter(attribute => attribute.referenceAttributeName != null && attribute.name == this.props.preselectedAttribute)[0];
					let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
					
					const query = 
						'{ ' +
						'	result:' + attribute.referencedKey.entityName.replace(".", "_") + 'List(' +
						'		limit: 1' +
						'       where: {' + subKeyAttribute.name + ': {EQ: ' + this.setQuotes(subKeyAttribute, this.props.preselectedValue) + '}}' +
						'	) {' + subKeyAttribute.name + ', ' 
								+ (entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes != null ? entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes.join(', ') + ', ' : '')
								+ this.getLabelAttributesQueryString(model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName], subKeyAttribute) +
						'	} ' +
						'}';
					
					//console.log(query);
					
					const variables = {
			    		authorization: this.props.context.accessToken
			    	};
					let request = JSON.stringify({query: query, variables: variables});
					fetch(this.props.context.baseUrl + "/graphql", {
						method: "POST",
						body: request
					})
					.then(response => response.json())
					.then(json => {
						if (json != null
								&& json.errors != null
								&& json.errors[0] != null
                                && (json.errors[0].message == "SessionTimeout" 
                                        || (json.errors[0].message.startsWith("ERROR: role \"") && json.errors[0].message.endsWith("\" does not exist")))) {
							document.location = '/login';
						}
						
						if (this._isMounted) {
							
							data[attribute.referenceAttributeName] = json.data["result"][0];
							
							this.setState({
								keyAttribute: keyAttribute,
								currentEntity: this.props.entity,
								data: data,
								variantAttributes: variantAttributes,
								inputRefs: this.inputRefs,
								attributes: attributes,
								uploadedData: {},
							}, () => {
								if (this.props.preselectedAttribute != null) {
									let attribute = attributes.filter(attribute => attribute.name === this.props.preselectedAttribute && attribute.referenceAttributeName != null)[0];
									if (this.inputRefs[this.props.preselectedAttribute] != null && this.inputRefs[this.props.preselectedAttribute].current != null) {
										let subKeyAttribute = Object.values(model.entities[attribute.referencedKey.entityName].keys).filter(key => key.primaryKey)[0].attributes[0];
										let selectedValue = (this.state.data == null || this.state.data[attribute.referenceAttributeName] == null ? null : {
											value: this.state.data[attribute.referenceAttributeName][subKeyAttribute.name], 
											label: this.state.data[attribute.referenceAttributeName] === null ? "" : this.getLabel(this.state.data[attribute.referenceAttributeName], model.entities[attribute.referencedKey.entityName], localModel.entities[attribute.referencedKey.entityName])
										});
										
										//console.log(selectedValue);
										this.inputRefs[attribute.name].current.select.select.setValue(selectedValue, 'select-option');
									}
								}
								this.setValues(attributes);
								this.props.context.hideActivityIndicator()
							});
						}
					})
					.catch(error => {
						console.log("!!!!! Retrying...");
						console.log(error);
						console.log(request);
						setTimeout(this.refreshDataThrottled(), 500);
					});
				}
				else {
                    const updatedData = {
                        ...data,
                        ...this.props.urlParams,
                    };
                    
					this.setState({
						keyAttribute: keyAttribute,
						currentEntity: this.props.entity,
						data: updatedData,
						variantAttributes: variantAttributes,
						inputRefs: this.inputRefs,
						attributes: attributes,
						uploadedData: {},
					}, () => {
						this.setValues(attributes);
						this.props.context.hideActivityIndicator()
					});
				}
                
                attributes.forEach(attribute => {
                    if (attribute.referenceAttributeName !== undefined 
                            && entityLocalModel.attributes[attribute.referenceAttributeName] != null 
                            && !attribute.array) {
                        
                        var additionalFilter = entityLocalModel.attributes[attribute.referenceAttributeName].additionalFilter;

                        if (additionalFilter != null) {
                            var parameters = additionalFilter.match(/\$[_A-Za-z][_0-9A-Za-z]*(\.\$[_A-Za-z][_0-9A-Za-z]*)*/g);
                            if (parameters != null) {
                                parameters.forEach(parameterName => {
                                    let subparameterNames = parameterName.split(".");
                                    if (subparameterNames.length == 1) {
                                        // TODO Faltaría un caso más en el que el atributo que se selecciona es también una referencia (AttributeViaAttribute)...Esto aplica cuando se carga un registro guardado previamente, se debería tomar el valor para usarlo en el filtro sin que el usuario seleccione nada.
                                        if (this.state.data != null && this.state.data[parameterName.substr(1)] != null) {
                                            additionalFilter = additionalFilter.replaceAll(parameterName, this.state.data[parameterName.substr(1)]);
                                        }
                                        else {
                                            additionalFilter = additionalFilter.replaceAll(parameterName, null);
                                        }
                                    }
                                });
                            }
                            if (additionalFilter.indexOf("$") == (-1)) {
                                this.setState((state, props) => {
                                    state[attribute.referenceAttributeName + "AdditionalFilter"] = additionalFilter;
                                    let obj = {};
                                    obj[attribute.referenceAttributeName + "AdditionalFilter"] = state[attribute.referenceAttributeName + "AdditionalFilter"];
                                    return obj;
                                });
                            }
                        }
                    }
                });
                
				this.setState({
					questions: undefined
				});
				
				if (entityLocalModel.questions != null) {
					Object.values(entityLocalModel.questions).filter(question => question.visible && !question.useIds).forEach(question => {
						
						const questionQuery = '{QuestionURL(id: ' + question.id + ')}';
						
						const questionQueryVariables = {
				    		authorization: this.props.context.accessToken
				    	};
						let request = JSON.stringify({query: questionQuery, variables: questionQueryVariables});
						
						fetch(this.props.context.baseUrl + "/graphql", {
							method: "POST",
							body: request
						})
						.then(response => response.json())
						.then(json => {
							if (json.data != null) {
								this.setState((state, props) => {
									let questions = state.questions;
									if (questions == null) {
										questions = {};
									}
									questions[question.name] = {
										url: json.data.QuestionURL
									};
									return {
										questions: questions,
									};
								});
							}
							else if (json.error != null) {
								console.log(json.error);
							}
						});
					});
				}
			}
		}
		catch (error) {
			history.push("/login");
		}
	}
	
	autoCompleteChangeHandler(changedAttribute, value) {
		const model = this.props.context.model;
		const entityModel = model.entities[this.props.entity];
		const entityLocalModel = localModel.entities[this.props.entity];
		const attributes = Object.values(this.state.attributes);
		
		if (this.state.variantAttributes.some(variantAttribute => variantAttribute.name == changedAttribute.name)) {
			console.log("!!!! WARNING -> This function has been discontinued because I thought nobody use it. In case you use it, contact with Airflows.");
			// No tiene sentido tener variantes dinámicas cuando todo se tiene que modelar. Usar tipos enumerados en su lugar.
			/*
			let selectedVariants = value && ["" + value["value"]];
			this.setState({ variants: selectedVariants });
			*/
		}
		
		attributes.forEach(attribute => {

			if (this.state.data != null
                    && (changedAttribute.name != attribute.name || this.state[attribute.referenceAttributeName + "AdditionalFilter"] == null)
					&& attribute.referenceAttributeName !== undefined 
					&& entityLocalModel.attributes[attribute.referenceAttributeName] != null 
					&& !attribute.array) {
                
				var additionalFilter = entityLocalModel.attributes[attribute.referenceAttributeName].additionalFilter;
                
				if (additionalFilter != null) {
					var parameters = additionalFilter.match(/\$[_A-Za-z][_0-9A-Za-z]*(\.\$[_A-Za-z][_0-9A-Za-z]*)*/g);
					if (parameters != null) {
						parameters.forEach(parameterName => {
							let subparameterNames = parameterName.split(".");
							if (subparameterNames.length == 1) {
								// TODO Faltaría un caso más en el que el atributo que se selecciona es también una referencia (AttributeViaAttribute)...Esto aplica cuando se carga un registro guardado previamente, se debería tomar el valor para usarlo en el filtro sin que el usuario seleccione nada.
								if (this.state.data[parameterName.substr(1)] != null) {
									additionalFilter = additionalFilter.replaceAll(parameterName, this.state.data[parameterName.substr(1)]);
								}
								else if (parameterName.substr(1) == changedAttribute.name) {
									if (value != null) {
										additionalFilter = additionalFilter.replaceAll(parameterName, value.value);
									}
								}
							}
							else if (subparameterNames.length == 2) {
								// TODO Faltaría un caso más en el que el atributo que se selecciona es también una referencia (AttributeViaAttribute)...Esto aplica cuando se carga un registro guardado previamente, se debería tomar el valor para usarlo en el filtro sin que el usuario seleccione nada.
								if (subparameterNames[0].substr(1) == changedAttribute.name) {
									if (value[subparameterNames[1].substr(1)] != null) {
										additionalFilter = additionalFilter.replaceAll(parameterName, value[subparameterNames[1].substr(1)]);
									}
									else {
										let referenceAttributeName = attributes.filter(attribute => attribute.name == subparameterNames[0].substr(1))[0]["referenceAttributeName"];
										let referencedEntity = (referenceAttributeName != null ? this.state.data[referenceAttributeName] : null);
										let theValue = (referencedEntity != null ? referencedEntity[subparameterNames[1].substr(1)] : null);
										if (theValue != null) {
											additionalFilter = additionalFilter.replaceAll(parameterName, theValue);
										}
									}
								}
							}
						});
					}
					if (additionalFilter.indexOf("$") == (-1)) {
						this.setState((state, props) => {
							state[attribute.referenceAttributeName + "AdditionalFilter"] = additionalFilter;
							let obj = {};
							obj[attribute.referenceAttributeName + "AdditionalFilter"] = state[attribute.referenceAttributeName + "AdditionalFilter"];
							return obj;
						});
					}
				}
			}
		});
	}
	
	renderGroup(tab, group) {
		//console.log(">> EntityView.render");
		
		const { classes, t } = this.props;
		const inputRefs = this.inputRefs;
		const { selectedTab } = this.props.context.state[this.props.entity];
		
		const model = this.props.context.model;
		
		const entityModel = model.entities[this.props.entity];
		const entityLocalModel = localModel.entities[this.props.entity];
		var attributes = Object.values(this.state.attributes);
		
		let selectedVariants = this.state.variants;
		
		for (var i = attributes.length - 1; i >= 0; i--) {
			if (entityLocalModel.attributes[attributes[i].name] 
					&& entityLocalModel.attributes[attributes[i].name].lastInRow
					&& entityLocalModel.attributes[attributes[i].name].tab == tab
					&& entityLocalModel.attributes[attributes[i].name].group == group) {
				attributes.splice(i + 1, 0, {emptyRow: true, name: (attributes[i].incomingReferenceAttributeName != null ? attributes[i].incomingReferenceAttributeName : attributes[i].name) + "Break"});
			}
		}
		
        for (var i = attributes.length - 1; i >= 0; i--) {
            if (entityLocalModel.attributes[attributes[i].name] 
                    && entityLocalModel.attributes[attributes[i].name].firstInRow
                    && entityLocalModel.attributes[attributes[i].name].tab == tab
                    && entityLocalModel.attributes[attributes[i].name].group == group) {
                attributes.splice(i, 0, {emptyRow: true, name: (attributes[i].incomingReferenceAttributeName != null ? attributes[i].incomingReferenceAttributeName : attributes[i].name) + "Break"});
            }
        }

		return attributes
				.map(attribute => {
			
			let attributeVariants = attribute.variants;
			let variantSelected = attributeVariants == null
					|| attributeVariants.length == 0
					|| selectedVariants != null && selectedVariants.some(variant => attributeVariants.includes("" + variant));
			
			return (attribute.emptyRow
					&&
				<Grid key={attribute.name} style={{padding: 0}} item sm={12}/>
			)
			||

			// Campos externos (external search)
			(attribute.externalSelectorUrl != null
					&& entityLocalModel.attributes[attribute.name] != null
					&& entityLocalModel.attributes[attribute.name].tab == tab
					&& entityLocalModel.attributes[attribute.name].group == group
					&& 
				<Grid key={attribute.name} 
						item 
						xs={attribute.xs || 12} 
						sm={attribute.sm || entityLocalModel.attributes[attribute.referenceAttributeName] && entityLocalModel.attributes[attribute.referenceAttributeName].sm || 6} 
						className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? classes.externalSelector : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
				>	
					<TextField
							multiline={attribute.multiline}
							inputRef={inputRefs[attribute.name]}
							label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
							style={{flex: "1 1 auto"}}
							inputProps={{
								"data-qa": attribute.name + "-input",
							}}
							InputLabelProps={{shrink: true}}
							autoFocus={attribute._autoFocus}
                            disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || !attribute.externalSelectorEditableValue
                                    || this.props.mode === 'view' 
                                    || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) 
                                    || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
					/>
					<Tooltip 
							title={t('search')} 
							className={classes.alignButton2} 
							disableFocusListener>
						<span>
							<IconButton
									disableRipple
									disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
									aria-label={t('search')}
									onClick={event => this.handleExternalSearch(attribute)}>
								<SearchIcon/>
							</IconButton>
						</span>
					</Tooltip>
					<Tooltip 
							title={t('clear')} 
							className={classes.alignButton2} 
							disableFocusListener>
						<span>
							<IconButton
									disableRipple
									disabled={attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
									aria-label={t('clear')}
									onClick={event => this.handleExternalClear(attribute)}>
								<ClearIcon/>
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			)
			||
			
			// Todos los campos que no son referencias
			(attribute.incomingReferenceAttributeName === undefined 
					&& attribute.referenceAttributeName === undefined
					&& entityLocalModel.attributes[attribute.name] != null
					&& entityLocalModel.attributes[attribute.name].tab == tab
					&& entityLocalModel.attributes[attribute.name].group == group
					&& 
				(
					// Campos de tipo firma manuscrita con un valor
					(
						entityLocalModel.attributes[attribute.name].type === 'SIGNATURE'
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<InputLabel className={classes.inputLabel} shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
								<div className={classes.dashedBorder}>
									{
										this.props.mode === "edit"
											&&
										<Tooltip title={t('clear')} className={classes.alignButton} disableFocusListener>
											<IconButton
													aria-label={t('clear')}
													onClick={event => this.inputRefs[attribute.name].current.clear()}>
												<ClearIcon/>
											</IconButton>
										</Tooltip>
									}
									<SignaturePad
											redrawOnResize
											ref={inputRefs[attribute.name]}
											options={{
												redrawOnResize: true,
											}}
									/>
								</div>
							</Grid>
					) 
					|| 
					
					// Campos de tipo documento con uno o varios valores
					(
						!attribute.array
								&& entityLocalModel.attributes[attribute.name].type === 'DOCUMENT'
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								data-qa={attribute.name + "-input"} 
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<InputLabel className={classes.inputLabel} shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
								<DropzoneArea 
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										onDrop={file => this.handleDropDocument(file, attribute)}
										onDelete={file => this.handleDeleteDocument(file, attribute)}
										fileObjects={this.state.uploadedData[attribute.name]}
										acceptedFiles={(attribute.acceptedFileTypes == null ? [] : attribute.acceptedFileTypes)}
										filesLimit={(attribute.array ? (attribute.maxFiles == null ? 5 : attribute.maxFiles) : 1)}
										maxFileSize={this.props.theme.documentMaxFileSizeMB * 1024 * 1024}
										entity={this.props.entity}
										entityId={this.props.entityId}
										attributeName={attribute.name}
										attributeIsArray={attribute.array}
										browseZip={attribute.browseZip}
										dropzoneText={(attribute.array ? t('multipleDragOrSelect') : t('dragOrSelect'))}
								/>
							</Grid>
					) 
					|| 

					// Campos de tipo BPMN Editor
					/*
					(
						entityLocalModel.attributes[attribute.name].type === 'BPMN_EDITOR'
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								data-qa={attribute.name + "-input"} 
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<InputLabel className={classes.inputLabel} shrink required={attribute.required}>{t('e.' + this.props.entity + '.a.' + attribute.name)}</InputLabel>
								<BpmnEditor
										data-qa={attribute.name + "-input"} 
										mode={this.props.mode}
										onClick={xml => this.handleBpmnDiagramChange(xml, attribute)} 
										xml={this.state.data[attribute.name]}
								/>
 							</Grid>
					) 
					|| 
					*/
					
					// Campos de código de barras
					(
						entityLocalModel.attributes[attribute.name].type === 'BARCODE'
								&& 
							<Grid key={attribute.name} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<Barcode
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										inputProps={{
											"data-qa": attribute.name + "-input",
											maxLength: attribute.length, 
											pattern: attribute.pattern, 
											title: attribute.pattern
										}}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										barcodeType={attribute.barcodeType}
								/>
							</Grid>
					)
					||
					
					// Campos de acción
					(
							!attribute.array
									&& entityLocalModel.attributes[attribute.name].action
									&& entityLocalModel.attributes[attribute.name].actionVisibleInForm
									&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
									&& attribute.enumType === undefined 
									&& 
								<Grid key={attribute.name} 
										item 
										xs={attribute.xs || 12} 
										sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
										className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
								>
									<Button 
                                            data-qa={attribute.name + "-button"}
											disabled={this.state.data[attribute.name] == null || this.state.data[attribute.name] == ""} 
											variant="contained"
											autoFocus={attribute._autoFocus}
											onClick={event => this.handleActionClick(event, this.state.data, attribute)}>
										{t('e.' + this.props.entity + '.a.' + attribute.name)}
									</Button>
								</Grid>
					)
					||
					
					// Campos de texto sencillos con un único valor o de tipo vector
					(
						!attribute.array
								&& (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].action || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
								&& !attribute.rich
								&& !attribute.password
								&& !attribute.color
								&& !attribute.icon
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR" || attribute.type === "VECTOR") 
								&& attribute.enumType === undefined 
								&& 
							<Grid key={attribute.name} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<TextField
										multiline={attribute.multiline}
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										rows={10}
										rowsMax={20}
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
											maxLength: (entityLocalModel.attributes[attribute.name].type != 'VECTOR' ? attribute.length : (-1)), 
											pattern: attribute.pattern, 
											title: attribute.pattern
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed 
												|| this.props.mode === 'view' 
												|| this.props.entity == 'Models.EntityReference' && attribute.name == 'name' 
												|| (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) 
												|| (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
								/>

								{
									this.props.entity == 'Models.Function'
											&& attribute.name == 'contents'
											&& this.state.showBlockly
											&&
										
										<Grid key={attribute.name + "_blocky"} 
												item 
												xs={attribute.xs || 12} 
												sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
												className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
										>
											<BlocklyComponent ref={e => this.simpleWorkspace = e}>
												<category name={t('logicCategory')} colour="#5C81A6">
													<block type="controls_if"></block>
													<block type="logic_compare">
														<field name="OP">EQ</field>
													</block>
													<block type="logic_operation">
														<field name="OP">AND</field>
													</block>
													<block type="logic_negate"></block>
													<block type="logic_boolean">
														<field name="BOOL">TRUE</field>
													</block>
													<block type="logic_null"></block>
													<block type="logic_ternary"></block>
												</category>
												<category name={t('loopsCategory')} colour="#5CA65C">
													<block type="controls_repeat_ext">
														<value name="TIMES">
															<shadow type="math_number">
																<field name="NUM">10</field>
															</shadow>
														</value>
													</block>
													<block type="controls_whileUntil">
														<field name="MODE">WHILE</field>
													</block>
													<block type="controls_for">
														<field name="VAR" id="C(8;cYCF}~vSgkxzJ+{O" variabletype="">i</field>
														<value name="FROM">
															<shadow type="math_number">
																<field name="NUM">1</field>
															</shadow>
														</value>
														<value name="TO">
															<shadow type="math_number">
																<field name="NUM">10</field>
															</shadow>
														</value>
														<value name="BY">
															<shadow type="math_number">
																<field name="NUM">1</field>
															</shadow>
														</value>
													</block>
													<block type="controls_forEach">
														<field name="VAR" id="Cg!CSk/ZJo2XQN3=VVrz" variabletype="">j</field>
													</block>
													<block type="controls_flow_statements">
														<field name="FLOW">BREAK</field>
													</block>
												</category>
												<category name={t('mathCategory')} colour="#5C68A6">
													<block type="math_round">
														<field name="OP">ROUND</field>
														<value name="NUM">
															<shadow type="math_number">
																<field name="NUM">3.1</field>
															</shadow>
														</value>
													</block>
													<block type="math_number">
														<field name="NUM">0</field>
													</block>
													<block type="math_single">
														<field name="OP">ROOT</field>
														<value name="NUM">
															<shadow type="math_number">
																<field name="NUM">9</field>
															</shadow>
														</value>
													</block>
													<block type="math_trig">
														<field name="OP">SIN</field>
														<value name="NUM">
															<shadow type="math_number">
																<field name="NUM">45</field>
															</shadow>
														</value>
													</block>
													<block type="math_constant">
														<field name="CONSTANT">PI</field>
													</block>
													<block type="math_number_property">
														<mutation divisor_input="false"></mutation>
														<field name="PROPERTY">EVEN</field>
														<value name="NUMBER_TO_CHECK">
															<shadow type="math_number">
																<field name="NUM">0</field>
															</shadow>
														</value>
													</block>
													<block type="math_arithmetic">
														<field name="OP">ADD</field>
														<value name="A">
															<shadow type="math_number">
																<field name="NUM">1</field>
															</shadow>
														</value>
														<value name="B">
															<shadow type="math_number">
																<field name="NUM">1</field>
															</shadow>
														</value>
													</block>
													<block type="math_on_list">
														<mutation op="SUM"></mutation>
														<field name="OP">SUM</field>
													</block>
													<block type="math_modulo">
														<value name="DIVIDEND">
															<shadow type="math_number">
																<field name="NUM">64</field>
															</shadow>
														</value>
														<value name="DIVISOR">
															<shadow type="math_number">
																<field name="NUM">10</field>
															</shadow>
														</value>
													</block>
													<block type="math_constrain">
														<value name="VALUE">
															<shadow type="math_number">
																<field name="NUM">50</field>
															</shadow>
														</value>
														<value name="LOW">
															<shadow type="math_number">
																<field name="NUM">1</field>
															</shadow>
														</value>
														<value name="HIGH">
															<shadow type="math_number">
																<field name="NUM">100</field>
															</shadow>
														</value>
													</block>
													<block type="math_random_int">
														<value name="FROM">
															<shadow type="math_number">
																<field name="NUM">1</field>
															</shadow>
														</value>
														<value name="TO">
															<shadow type="math_number">
																<field name="NUM">100</field>
															</shadow>
														</value>
													</block>
													<block type="math_random_float"></block>
												</category>
												<category name={t('textCategory')} colour="#5CA68D">
													<block type="text_charAt">
														<mutation at="true"></mutation>
														<field name="WHERE">FROM_START</field>
														<value name="VALUE">
															<block type="variables_get">
																<field name="VAR" id="q@$ZF(L?Zo/z`d{o.Bp!" variabletype="">text</field>
															</block>
														</value>
													</block>
													<block type="text">
														<field name="TEXT"></field>
													</block>
													<block type="text_append">
														<field name="VAR" id=":};P,s[*|I8+L^-.EbRi" variabletype="">item</field>
														<value name="TEXT">
															<shadow type="text">
																<field name="TEXT"></field>
															</shadow>
														</value>
													</block>
													<block type="text_length">
														<value name="VALUE">
															<shadow type="text">
																<field name="TEXT">abc</field>
															</shadow>
														</value>
													</block>
													<block type="text_isEmpty">
														<value name="VALUE">
															<shadow type="text">
																<field name="TEXT"></field>
															</shadow>
														</value>
													</block>
													<block type="text_indexOf">
														<field name="END">FIRST</field>
														<value name="VALUE">
															<block type="variables_get">
																<field name="VAR" id="q@$ZF(L?Zo/z`d{o.Bp!" variabletype="">text</field>
															</block>
														</value>
														<value name="FIND">
															<shadow type="text">
																<field name="TEXT">abc</field>
															</shadow>
														</value>
													</block>
													<block type="text_join">
														<mutation items="2"></mutation>
													</block>
													<block type="text_getSubstring">
														<mutation at1="true" at2="true"></mutation>
														<field name="WHERE1">FROM_START</field>
														<field name="WHERE2">FROM_START</field>
														<value name="STRING">
															<block type="variables_get">
																<field name="VAR" id="q@$ZF(L?Zo/z`d{o.Bp!" variabletype="">text</field>
															</block>
														</value>
													</block>
													<block type="text_changeCase">
														<field name="CASE">UPPERCASE</field>
														<value name="TEXT">
															<shadow type="text">
																<field name="TEXT">abc</field>
															</shadow>
														</value>
													</block>
													<block type="text_trim">
														<field name="MODE">BOTH</field>
														<value name="TEXT">
															<shadow type="text">
																<field name="TEXT">abc</field>
															</shadow>
														</value>
													</block>
													<block type="text_print">
														<value name="TEXT">
															<shadow type="text">
																<field name="TEXT">abc</field>
															</shadow>
														</value>
													</block>
													<block type="text_prompt_ext">
														<mutation type="TEXT"></mutation>
														<field name="TYPE">TEXT</field>
														<value name="TEXT">
															<shadow type="text">
																<field name="TEXT">abc</field>
															</shadow>
														</value>
													</block>
												</category>
												<category name={t('listsCategory')} colour="#745CA6">
													<block type="lists_indexOf">
														<field name="END">FIRST</field>
														<value name="VALUE">
															<block type="variables_get">
																<field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>
															</block>
														</value>
													</block>
													<block type="lists_create_with">
														<mutation items="0"></mutation>
													</block>
													<block type="lists_repeat">
														<value name="NUM">
															<shadow type="math_number">
																<field name="NUM">5</field>
															</shadow>
														</value>
													</block>
													<block type="lists_length"></block>
													<block type="lists_isEmpty"></block>
													<block type="lists_create_with">
														<mutation items="3"></mutation>
													</block>
													<block type="lists_getIndex">
														<mutation statement="false" at="true"></mutation>
														<field name="MODE">GET</field>
														<field name="WHERE">FROM_START</field>
														<value name="VALUE">
															<block type="variables_get">
																<field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>
															</block>
														</value>
													</block>
													<block type="lists_setIndex">
														<mutation at="true"></mutation>
														<field name="MODE">SET</field>
														<field name="WHERE">FROM_START</field>
														<value name="LIST">
															<block type="variables_get">
																<field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>
															</block>
														</value>
													</block>
													<block type="lists_getSublist">
														<mutation at1="true" at2="true"></mutation>
														<field name="WHERE1">FROM_START</field>
														<field name="WHERE2">FROM_START</field>
														<value name="LIST">
															<block type="variables_get">
																<field name="VAR" id="e`(L;x,.j[[XN`F33Q5." variabletype="">list</field>
															</block>
														</value>
													</block>
													<block type="lists_split">
														<mutation mode="SPLIT"></mutation>
														<field name="MODE">SPLIT</field>
														<value name="DELIM">
															<shadow type="text">
																<field name="TEXT">,</field>
															</shadow>
														</value>
													</block>
													<block type="lists_sort">
														<field name="TYPE">NUMERIC</field>
														<field name="DIRECTION">1</field>
													</block>
												</category>
												<sep></sep>
												<category name={t('variablesCategory')} colour="#A65C81" custom="VARIABLE"></category>
											</BlocklyComponent>
											<Tooltip title={t('generateCode')} disableFocusListener>
												<Button	variant="contained" aria-label={t('generateCode')} onClick={this.handleGenerateCode}>
													<SettingsIcon/>
													{t('generateCode')}
										        </Button>
											</Tooltip>
											
										</Grid>
								}
							</Grid>
					)
					||
					
					// Campos de contraseña
					(
						!attribute.array
								&& !attribute.rich
								&& attribute.password
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&& 
							<Grid key={attribute.name} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<FormControl fullWidth>
									<InputLabel	shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
									<PasswordField
											inputRef={inputRefs[attribute.name]}
											fullWidth
											style={{height: "32px", lineHeight: "32px", padding: "0"}}
											autoComplete="new-password"
											inputProps={{
												"data-qa": attribute.name + "-input",
												maxLength: attribute.length, 
												pattern: attribute.pattern, 
												title: attribute.pattern
											}}
											autoFocus={attribute._autoFocus}
											disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
									/>
								</FormControl>
							</Grid>
					)
					||
					
					// Selector de color
					(
						!attribute.array
								&& attribute.color
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& 

							<Grid key={attribute.name} 
									data-qa={attribute.name + "-input"} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<FormControl fullWidth>
									<InputLabel style={{position: "relative", height: "32px", lineHeight: "32px"}} shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
									<ChromePicker 
											color={(this.state.data[attribute.name] == null ? "#000000" : this.state.data[attribute.name])} 
											onChangeComplete={(event) => this.handleColorChange(event, attribute)}
									/>
								</FormControl>
							</Grid>
					)
					||
					
					// Campos de texto enriquecido con un único valor
					(
						!attribute.array
								&& (!entityLocalModel.attributes[attribute.name] || !entityLocalModel.attributes[attribute.name].action || !entityLocalModel.attributes[attribute.name].actionVisibleInForm)
								&& attribute.rich
								&& !attribute.password
								&& !attribute.color
								&& !attribute.icon
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&&
							<Grid key={attribute.name} 
									item 
									xs={attribute.xs || 12} 
									sm={attribute.sm || (attribute.multiline ? 12 : 6)} 
									className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<FormControl
										required={attribute.required}
										fullWidth>
									<InputLabel style={{position: "relative", height: "32px", lineHeight: "32px"}} shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
									<ReactQuill
											theme="snow"
											/*
											modules={{toolbar: [
											  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
											  ['blockquote', 'code-block'],
											
											  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
											  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
											  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
											  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
											  [{ 'direction': 'rtl' }],                         // text direction
											
											  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
											  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
											
											  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
											  [{ 'font': [] }],
											  [{ 'align': [] }],
											
											  ['clean']                                         // remove formatting button
											]}}	
											*/
											modules={{toolbar: [
									            [{ "font": [] }, { "size": ["small", false, "large", "huge"] }], // custom dropdown
									            ["bold", "italic", "underline", "strike"],
									            [{ "color": [] }, { "background": [] }],
									            [{ "script": "sub" }, { "script": "super" }],
									            [{ "header": 1 }, { "header": 2 }, "blockquote", "code-block"],
									            [{ "list": "ordered" }, { "list": "bullet" }, { "indent": "-1" }, { "indent": "+1" }],
									            [/*{ "direction": "rtl" },*/ { "align": [] }],
									            /*["link", "image", "video", "formula"],*/
									            ["clean"]
									        ]}}
											/*
											modules={{toolbar: [
											      [{ 'header': [1, 2, false] }],
											      ['bold', 'italic', 'underline','strike', 'blockquote'],
											      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
											      ['link', 'image'],
											      ['clean']
											]}}
											*/
											value={(this.state.data[attribute.name] == null ? "" : this.state.data[attribute.name])}
											onChange={(value) => this.handleRichTextChange(value, attribute)} 
											readOnly={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
									/>
								</FormControl>
							</Grid>
					) 
					||	
					
					// Selector de icono
					(
						(attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType !== undefined 
								&& attribute.icon
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								style={{paddingTop: 0}}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<FormControl 
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										fullWidth>
									<InputLabel style={{position: "relative", height: "3px", lineHeight: "3px"}} shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
									<Select 
											value={(this.state.data[attribute.name] == null ? "#000000" : this.state.data[attribute.name])} 
											onChange={(event) => this.handleIconChange(event, attribute)}
											autoFocus={attribute._autoFocus}
											data-qa={attribute.name + "-input"}
									>
										{
											!attribute.array
												&&
											<MenuItem value={null}></MenuItem>
										}
										{ 
											Object.values(attribute.enumType.values).map(value => (
												<MenuItem data-qa={value + "-icon"} key={value} value={value}><Icon>{value}</Icon>&nbsp;{value}</MenuItem>
											))
										}
									</Select>
								</FormControl>
							</Grid>
					)
					||	
					
					// Campos enumerados con un único valor o con múltiples valores
					(
						(attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType !== undefined 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								style={{paddingTop: "10px"}}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<FormControl 
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										fullWidth>
									<InputLabel style={{position: "relative", height: "3px", lineHeight: "3px"}} shrink>{t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")}</InputLabel>
									{/*
										onChange={e => {
											if (this.props.entity == 'Models.Function' && attribute.name == 'language') {
												if (this.state.showBlockly != (inputRefs[attribute.name].current.value == 'ECMAScriptNashorn')) {
													this.setState({showBlockly: inputRefs[attribute.name].current.value == 'ECMAScriptNashorn'});
												}
											}
										}}
										*/}
									<Select
											inputRef={inputRefs[attribute.name]}
											multiple={attribute.array}
											native
											onChange={event => {
												if (attribute.variantSelector) {
													let selectedVariants = [];
													// Add selected variants
													this.state.variantAttributes.forEach(variantAttribute => {
														if (variantAttribute.array) {
															selectedVariants = selectedVariants.concat(Object.values(this.inputRefs[variantAttribute.name].current.options).filter(option => option.selected).map(option => option.value));
														}
														else {
															if (this.inputRefs[variantAttribute.name].current != null && this.inputRefs[variantAttribute.name].current.value != null && this.inputRefs[variantAttribute.name].current.value != "") {
																selectedVariants.push(this.inputRefs[variantAttribute.name].current.value);
															}
														}
													});
													
													// Remove selected variants of invisible fields
													let variantsToRemove = [];
													this.state.variantAttributes.forEach(variantAttribute => {
														if (variantAttribute.variants != null && variantAttribute.variants.length > 0 && !variantAttribute.variants.some(element => selectedVariants.includes(element))) {
															if (variantAttribute.array) {
																variantsToRemove = variantsToRemove.concat(Object.values(this.inputRefs[variantAttribute.name].current.options).filter(option => option.selected).map(option => option.value));
															}
															else {
																if (this.inputRefs[variantAttribute.name].current.value != null && this.inputRefs[variantAttribute.name].current.value != "") {
																	variantsToRemove.push(this.inputRefs[variantAttribute.name].current.value);
																}
															}
															
														}
													});
													selectedVariants = selectedVariants.filter(element => !variantsToRemove.includes(element));
													
													//console.log("Selected variants: " + selectedVariants);
													this.setState({ variants: selectedVariants });
												}
											}}
											autoFocus={attribute._autoFocus}
											classes={{
												icon: (attribute.array ? classes.selectMultipleIcon : classes.selectIcon)
											}}
											inputProps={{
												"data-qa": attribute.name + "-input",
											}}>
										{
											!attribute.array
												&&
											<option value=""></option>
										}
                                        {
                                            Object.values(attribute.enumType.values)
                                                .sort((a, b) => {
                                                    const labelA = t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + a);
                                                    const labelB = t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + b);
                                                    return labelA.localeCompare(labelB);
                                                })
                                                .map(value => (
                                                    <option key={value} value={value}>
                                                        {t('enums.' + attribute.enumType.schema + '.' + attribute.enumType.name + '.v.' + value)}
                                                    </option>
                                                ))
                                        }                                        
									</Select>
								</FormControl>
							</Grid>
					)
					||
					
					// Campos booleanos con un único valor
					(
						!attribute.array 
								&& attribute.type === "BOOLEAN" 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<FormControlLabel
										control={
												<Checkbox color="secondary" 
														indeterminate={this.state.data[attribute.name] == null}
														onChange={(event, checked) => this.handleCheckboxChange(event, checked, attribute)} 
														checked={this.state.data[attribute.name] === true}
														inputProps={{
															"data-qa": attribute.name + "-input"
														}}
												/>
										}
										inputRef={inputRefs[attribute.name]}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										label={t('e.' + this.props.entity + '.a.' + attribute.name)}/>
							</Grid>
					)
					||
					
					// Campos de tipo entero con un único valor
					// Campos de tipo numérico con decimales con un único valor
					(
						!attribute.array 
								&& (attribute.type === "INTEGER"
										|| attribute.type === "SMALLINT"
										|| attribute.type === "BIGINT"
										|| attribute.type === "SERIAL"
										|| attribute.type === "DECIMAL"
										|| attribute.type === "DOUBLE_PRECISION"
										|| attribute.type === "REAL"
										|| attribute.type === "MONEY"
										|| attribute.type === "SMALLSERIAL"
										|| attribute.type === "BIGSERIAL") 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								{
									((this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined))))
										&&
											<TextField 
													style={{display: (attribute.hidden ? 'none' : 'inline-flex')}}
													inputRef={inputRefs[attribute.name]}
													label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
													fullWidth
													inputProps={{
														"data-qa": attribute.name + "-input",
													}}
													InputLabelProps={{shrink: true}}
													disabled
											/>
										||
											<TextField 
													style={{display: (attribute.hidden ? 'none' : 'inline-flex')}}
													inputRef={inputRefs[attribute.name]}
													label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
													fullWidth
													inputProps={{
														"data-qa": attribute.name + "-input",
														min: attribute.min, 
														max: attribute.max, 
														step: attribute.step || "any"
													}}
													InputLabelProps={{shrink: true}}
													autoFocus={attribute._autoFocus}
													type="number"
											/>
								}
							</Grid>
					) 
					||
					
					// Campos de tipo fecha con un único valor
					(
						!attribute.array 
								&& attribute.type === "DATE" 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<TextField 
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="date"
								/>
							</Grid>
					) 
					|| 
					
					// Campos de tipo timestamp con un único valor
					(
						!attribute.array 
								&& (attribute.type === "TIMESTAMP" || attribute.type === "TIMESTAMP_WITH_TIME_ZONE") 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<TextField 
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
											"step": 1,
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="datetime-local"
								/>
							</Grid>
					) 
					||
					
					// Campos de tipo hora con un único valor
					(
						!attribute.array 
								&& (attribute.type === "TIME" || attribute.type === "TIME_WITH_TIME_ZONE") 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<TextField 
										inputRef={inputRefs[attribute.name]}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										fullWidth
										inputProps={{
											"data-qa": attribute.name + "-input",
										}}
										InputLabelProps={{shrink: true}}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										type="time"
								/>
							</Grid>
					) 
					||
					
					// Campos de tipo texto con varios valores
					(
						attribute.array 
								&& (attribute.type === "TEXT" || attribute.type === "CHAR" || attribute.type === "VARCHAR") 
								&& attribute.enumType === undefined 
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								style={{paddingTop: "4px"}}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<ChipInput
										allowDuplicates={entityLocalModel.attributes[attribute.name].allowDuplicates}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										fullWidth
										blurBehavior="add"
										InputProps={{
											inputProps: {
												maxLength: attribute.length, 
												"data-qa": attribute.name + "-input",
												pattern: attribute.pattern, 
												title: attribute.pattern,
												/*
												onKeyDown: (e) => {
													alert(e.key + ", " + e.keyCode);
													console.log(e);
												},
												*/
											},
										}}
										InputLabelProps={{
											shrink: true,
										}}
										value={(this.state.data == null || this.state.data[attribute.name] == null ? [] : this.state.data[attribute.name])}
										onAdd={(chip) => this.handleAddChip(chip, attribute)}
										onDelete={(chip, index) => this.handleDeleteChip(chip, index, attribute)}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
								/>
							</Grid>
					) 
					|| 
					
					// Campos de tipo POINT (pensado para latitud y longitud
					(
						!attribute.array 
								&& attribute.type === "POINT"
								&& 
							<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<Grid container>
									<Grid key="latitude" item xs={6} sm={6}>
										<TextField 
												inputRef={inputRefs[attribute.name + "Latitude"]}
												label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (" + t('latitude') + ")" + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
												fullWidth
												inputProps={{
													"data-qa": attribute.name + "-latitude-input",
													min: -90, 
													max: 90, 
													step: 0.000000000001
												}}
												InputLabelProps={{shrink: true}}
												autoFocus={attribute._autoFocus}
												disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
												type="number"
										/>
									</Grid>
									<Grid key="longitude" item xs={6} sm={6}>
										<TextField 
												inputRef={inputRefs[attribute.name + "Longitude"]}
												label={t('e.' + this.props.entity + '.a.' + attribute.name) + " (" + t('longitude') + ")" + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
												fullWidth
												inputProps={{
													"data-qa": attribute.name + "-longitude-input",
													min: -180, 
													max: 180, 
													step: 0.000000000001
												}}
												InputLabelProps={{shrink: true}}
												disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
												type="number"
										/>
									</Grid>
								</Grid>
							</Grid>
					) 
					||
					
					// Campos no soportados
					(
						<Grid key={attribute.name} item xs={attribute.xs || 12} sm={attribute.sm || 6}
							className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.name].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
						>
							<Typography variant="h6" className={classes.title} color="inherit" noWrap>NOT_SUPPORTED_YET</Typography>
							<span>{attribute.name + ", " + attribute.type + ", " + (this.state.data == null ? "" : this.state.data[attribute.name])}</span>
						</Grid>
					)
				)
			)
			
			// Referencias directas
			|| (
				this.state.data != null
						&& attribute.referenceAttributeName !== undefined 
						&& (attribute.externalSelectorUrl == null || attribute.externalSelectorUrl == "")
						&& entityLocalModel.attributes[attribute.referenceAttributeName] != null 
						&& entityLocalModel.attributes[attribute.referenceAttributeName].tab == tab
						&& entityLocalModel.attributes[attribute.referenceAttributeName].group == group
						&&
					
					// Campos de tipo referencia directa con un único valor
					((
						!attribute.array
								&&
							<Grid key={attribute.referenceAttributeName} data-qa={attribute.referenceAttributeName + "-input"} item xs={entityLocalModel.attributes[attribute.referenceAttributeName].xs || 12} sm={entityLocalModel.attributes[attribute.referenceAttributeName].sm || 6}
								style={{paddingTop: "8px"}}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.referenceAttributeName].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<AutoComplete
										additionalFilter={this.state[attribute.referenceAttributeName + "AdditionalFilter"]}
										additionalAttributes={entityLocalModel.attributes[attribute.referenceAttributeName].additionalAttributes}
										entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
										asyncRef={inputRefs[attribute.name]}
										autoFocus={attribute._autoFocus}
										disabled={entityLocalModel.attributes[attribute.name] && entityLocalModel.attributes[attribute.name].computed 
												|| this.props.mode === 'view' 
												|| this.props.entity == 'Models.EntityReference' && attribute.name == 'container' 
												|| this.props.entity == 'Models.EntityReference' && attribute.name == 'referencedKey' 
												|| (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined)
                                                || (this.props.entityId != null && entityLocalModel.attributes[attribute.name].editableOnInsertOnly) 
												|| (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
										onChange={value => this.autoCompleteChangeHandler(attribute.attributes[0], value)}
								/>
							</Grid>
					)
					||
					
					// Campos de tipo referencia directa con múltiples valores
					(
						attribute.array
								&&
							<Grid key={attribute.referenceAttributeName} item xs={entityLocalModel.attributes[attribute.referenceAttributeName].xs || 12} sm={entityLocalModel.attributes[attribute.referenceAttributeName].sm || 6}
								style={{paddingTop: "8px"}}
								className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.referenceAttributeName].tab === selectedTab) ? '' : (attribute.spaceReserved ? classes.hiddenSpaceReserved : classes.hidden)}
							>
								<AutoComplete
										entityName={Object.values(entityModel.references).filter(reference => reference.name === attribute.name)[0].referencedKey.entityName}
										asyncRef={inputRefs[attribute.name]}
										autoFocus={attribute._autoFocus}
										disabled={(this.props.entityId != null && attribute.editableOnInsertOnly) || attribute.computed || this.props.mode === 'view' || (!model.super && ((this.props.entityId != null && attribute.privileges["UPDATE"] === undefined) || (this.props.entityId == null && attribute.privileges["INSERT"] === undefined)))}
										label={t('e.' + this.props.entity + '.a.' + attribute.name) + (entityLocalModel.attributes[attribute.name].required ? " *" : "")} 
								/>
							</Grid>
					))			
			)
			
			// Referencias inversas
			|| (
				this.state.data != null
						&& attribute.incomingReferenceAttributeName !== undefined 
						&& !(attribute.entityName == "Models.EntityReferenceAttribute" && attribute.incomingReferenceAttributeName == "EntityReferenceAttributeListViaContainer")
						&& entityLocalModel.attributes[attribute.incomingReferenceAttributeName] != null 
						&& entityLocalModel.attributes[attribute.incomingReferenceAttributeName].tab == tab
						&& entityLocalModel.attributes[attribute.incomingReferenceAttributeName].group == group
						&& (model.edition == 'Enterprise' || (attribute.entityName != "Models.ExternalEntityPermission" && attribute.entityName != "Models.CustomTriggerPermission" && attribute.entityName != "Models.RowLevelPermission" && attribute.entityName != "Models.EntityAttributePermission" && attribute.entityName != "Models.NetworkPermission" && attribute.entityName != "Models.SchemaPermission"))
						&&
					<Grid key={attribute.incomingReferenceAttributeName} item xs={attribute.xs || 12} sm={attribute.sm || 12}
						className={variantSelected && (tab == null || entityLocalModel.attributes[attribute.incomingReferenceAttributeName].tab === selectedTab) ? '' : classes.hidden}
					>
						<EntityList editionDisabled={this.props.mode === 'edit'} disabled={this.props.entityId == undefined} entity={attribute.entityName} filterAttribute={attribute.name} filterValue={this.props.entityId} refresh={this.state.refresh} theme={this.props.theme}/>
					</Grid>
			)
		});
	}
	
	// Render
	render() {
		//console.log(">> EntityView.render");
		
		if (this.state != null 
				&& this.props.context.model !== null
				&& this.state.currentEntity === this.props.entity) {
			
			const { classes, t } = this.props;
			const { selectedTab } = this.props.context.state[this.props.entity];
			
			const model = this.props.context.model;
			
			const entityModel = model.entities[this.props.entity];
			const entityLocalModel = localModel.entities[this.props.entity];
			
			//console.log(entityModel);
			//console.log(entityLocalModel);
			
			const Fragment = React.Fragment;
			
			const label = this.getLabel(this.state.data, entityModel, entityLocalModel);
			
			let questions = entityLocalModel.questions != null && Object.values(entityLocalModel.questions).filter(question => question.visible && (this.props.entityId !== undefined || !question.useIds));
			if (questions == null) {
				questions = [];
			}
			
			let selectedVariants = this.state.variants;
			
			return (
				<div onKeyDown={this.handleKeyDown}>
					<form onSubmit={this.handleSaveClick}>
						<Grid container>
							<Grid key="data" item xs={12} sm={questions.length > 0 ? 8 : 12} lg={questions.length > 0 ? 9 : 12} xl={questions.length > 0 ? 10 : 12}>
								<Paper square elevation={1}>
									<div>
										<Toolbar className={classes.toolbar}>
											{ 
												localModel.entities[entityModel.schema + "." + entityModel.name].icon != null ? (
													<div style={{display: "flex", alignItems: "center"}}>
														<Icon>{localModel.entities[entityModel.schema + "." + entityModel.name].icon}</Icon>
														<Typography variant="h6" className={classes.title} color="inherit" style={{paddingLeft: "10px"}}>{t('e.' + this.props.entity + '.pluralName')}{this.props.entityId == null && " → " + t('newRecord')}{label != null && label != "" && this.props.entityId != null && <> → &nbsp;{label}</>}</Typography>
													</div>
												)
												: (
													<Typography variant="h6" className={classes.title} color="inherit">{t('e.' + this.props.entity + '.pluralName')}{this.props.entityId == null && " → " + t('newRecord')}{label != null && label != "" && this.props.entityId != null && <> → &nbsp;{label}</>}</Typography>
												)
											}
											
											<div className={classes.spacer}/>
											{
												(
													this.props.mode === 'view' 
															&& (model.super	|| model.entities[this.props.entity].privileges["UPDATE"] !== undefined) 
															&& (this.props.entity != 'Models.EntityAttribute' || this.state.data["name"] != "id")
															&&
														<Tooltip title={t('edit')} disableFocusListener>
															<Button
																	data-qa="edit-button"
																	aria-label={t('edit')}
																	style={{borderRadius: "5px"}}
																	color="secondary"
																	variant="outlined"
																	disableRipple
																	onClick={this.handleEditClick}>
																<EditIcon style={{marginRight: "10px"}}/>
																{t('edit')}
															</Button>
														</Tooltip>
												) || (
													this.props.mode === 'edit' 
															&& 
														<>
															{
																Object.values(entityModel.attributes).filter(attribute => attribute.mrzField != null).length > 0
																		&&
																	<>
																		<input type="file" capture="environment" accept="image/*" ref={this.uploadMrz} style={{display: "none"}} onChange={this.handleUploadMrzChange}></input>
																		<Tooltip title={t('scanMrz')} disableFocusListener>
																			<IconButton 
																					data-qa="scan-mrz-button"
																					onClick={this.handleMrzClick}
																					aria-label={t('scanMrz')}>
																				<CreditCardIcon/>
																			</IconButton>
																		</Tooltip>
																	</>
															}
															<Tooltip title={t('save')} disableFocusListener>
																<Button 
																		data-qa="save-button"
																		type="submit"
																		style={{borderRadius: "5px"}}
																		color="secondary"
																		variant="outlined"
																		disableRipple
																		aria-label={t('save')}>
																	<SaveIcon style={{marginRight: "10px"}}/>
																	{t('save')}
																</Button>
															</Tooltip>
															<Tooltip title={t('cancel')} disableFocusListener>
																<Button
																		data-qa="cancel-button"
																		style={{borderRadius: "5px", marginLeft: "10px"}}
																		color="secondary"
																		variant="outlined"
																		disableRipple
																		aria-label={t('cancel')}
																		onClick={this.handleCancelClick}>
																	<CancelIcon style={{marginRight: "10px"}}/>
																	{t('cancel')}
																</Button>
															</Tooltip>
														</>
												)
											}
										</Toolbar>
									</div>
									<div className={classes.paper}>
										<Grid container spacing={24}>
											{
												// Elements with !tab && !group
												this.renderGroup(null, null)
											}
											{
												// Elements with !tab && group
												entityLocalModel.groups != null
														&& Object.values(entityLocalModel.groups).sort((a, b) => a.order - b.order).map(group => 
																Object.values(entityLocalModel.attributes)
																		.filter(attribute => attribute.group === group.id 
																				&& attribute.tab == null
																				&& (attribute.variants ==  null || attribute.variants.length == 0 || selectedVariants != null && selectedVariants.some(variant => attribute.variants.includes("" + variant)))
																		).length > 0
														&&
													<Grid key={group.id} container spacing={24} style={{margin: 12, backgroundColor: "#f8f8f8", borderWidth: 1, borderStyle: "dashed", borderColor: "#dddddd"}}>
														<Grid key={group.id} item xs={12}>
															<Typography variant="subtitle2" 
																	color="inherit" 
																	noWrap>
																{t('e.' + this.props.entity + '.groups.' + group.id)}
															</Typography>
														</Grid>
														{
															this.renderGroup(null, group.id)
														}
													</Grid>
												)
											}
											{
												// Tabs
												entityLocalModel.tabs != null
														&& Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab != null).length > 0
														&&
												<Grid item xs={12}>
													<Tabs classes={{root: classes.tabsRoot}} value={selectedTab} onChange={this.handleChangeSelectedTab}>
														{
															Object.values(entityLocalModel.tabs).sort((a, b) => a.order - b.order)
																	.filter(tab => localModel.edition == 'Enterprise' || this.props.entity != 'Models.Entity' || (tab.name != 'actions')) // && tab.name != 'intelligence'))
																	.map(tab => Object.values(entityLocalModel.attributes).filter(attribute => attribute.tab === tab.id && (attribute.variants ==  null || attribute.variants.length == 0 || selectedVariants != null && selectedVariants.some(variant => attribute.variants.includes("" + variant))))
																	.length > 0
																	&&
																<Tab data-qa={"tab-" + tab.id + "-button"} value={tab.id} key={tab.id} classes={{root: classes.tabRoot}} label={t('e.' + this.props.entity + '.tabs.' + tab.id)}/>
															)
														}
													</Tabs>
												</Grid>
											}
											{
												// Elements with tab && !group
												entityLocalModel.tabs != null
													&& Object.values(entityLocalModel.tabs).map(tab => this.renderGroup(tab.id, null))
											}
											{
												// Elements with tab && group
												entityLocalModel.groups != null
														&& entityLocalModel.tabs != null
														&& Object.values(entityLocalModel.tabs).map(tab =>
															Object.values(entityLocalModel.groups).sort((a, b) => a.order - b.order).map(group => 
																Object.values(entityLocalModel.attributes)
																		.filter(attribute => attribute.group === group.id && attribute.tab == tab.id && (attribute.variants ==  null || attribute.variants.length == 0 || selectedVariants != null && selectedVariants.some(variant => attribute.variants.includes("" + variant))))
																		.length > 0
														&&
													<Grid key={group.id} container spacing={24} style={{margin: 12, backgroundColor: "#f8f8f8", borderWidth: 1, borderStyle: "dashed", borderColor: "#dddddd"}}	className={tab.id === selectedTab ? '' : classes.hidden}>
														<Grid key={group.id} 
																item xs={12}
																className={tab.id === selectedTab ? '' : classes.hidden}
														>
															<Typography variant="subtitle2" 
																	color="inherit" 
																	noWrap>
																{t('e.' + this.props.entity + '.groups.' + group.id)}
															</Typography>
														</Grid>
														{
															this.renderGroup(tab.id, group.id)
														}
													</Grid>
												))
											}
										</Grid>
									</div>
								</Paper>
							</Grid>
							{
								questions.length > 0 
									&&
								<Grid item xs={12} sm={4} lg={3} xl={2}>
									{
										questions.sort((a, b) => (a.order == null ? Infinity : a.order) - (b.order == null ? Infinity : b.order)).map(question => (
											this.state.questions != null
													&& this.state.questions[question.name] != null
													&&
												<div key={question.name}>
													<Paper square elevation={1}>
														<div>
															<Toolbar className={classes.toolbar}>
																<Grid container spacing={24} justify="flex-start" alignItems="center">
																	<Grid item xs sm>
																		<Typography variant="h6" color="inherit" noWrap>{t('e.' + this.props.entity + '.questions.' + question.name)}</Typography>
																	</Grid>
																	
																	<Grid item xs sm style={{textAlign: "end", whiteSpace: "nowrap"}}>
																		<Tooltip title={t('fullscreen')} disableFocusListener>
																			<div>
																				<IconButton
																						aria-label={t('fullscreen')} 
																						onClick={event => document.getElementById(question.name).requestFullscreen()}>
																					<FullscreenIcon/>
																				</IconButton>
																			</div>
																		</Tooltip>
																	</Grid>
																</Grid>
															</Toolbar>
														</div>
														<div className={classes.paperBottom}>
															<Grid item>
																<iframe id={question.name} allowFullScreen scrolling="no" className={classes.iframe} src={this.props.context.baseUrl + "/dashboards/embed/question/" + this.state.questions[question.name].url + "#bordered=false&titled=false"}></iframe>
															</Grid>
														</div>
													</Paper>
												</div>
											))
									}
								</Grid>
							}
						</Grid>
					</form>
					<Snackbar
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							autoHideDuration={5000}
							onClose={event => this.setState({ messageOpened: false })}
							open={this.state && this.state.messageOpened}>
						<SnackbarContent
								className={this.state.messageError ? classes.snackbarError : classes.snackbar}
								message={<><span className={classes.message}>{this.state.messageError && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
						/>
					</Snackbar>
				</div>
			);
		}
		else {
			return null;
		}
	}
}

EntityView.propTypes = {
	context: PropTypes.object.isRequired,
	t: PropTypes.func.isRequired,
	classes: PropTypes.object.isRequired,
	entity: PropTypes.string.isRequired,
	entityId: PropTypes.string,
	mode: PropTypes.string.isRequired,
	preselectedAttribute: PropTypes.string,
	preselectedValue: PropTypes.string,
};

export default withStyles(styles)(withContext(withI18n()(EntityView)));
