import BpmnModeler from 'bpmn-js/lib/Modeler';
import BpmnViewer from 'bpmn-js/dist/bpmn-navigated-viewer.production.min.js';

import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withContext } from './App';
import { withI18n } from 'react-i18next';
import PropTypes from 'prop-types';

const styles = theme => ({
});

class BpmnEditor extends Component {

	constructor(props) {
		super(props);
	    this.state = {};
	    this.containerRef = React.createRef();
	    
	    this.refreshData = this.refreshData.bind(this);
	}

	refreshData() {
		var thisInstance = this;

		if (this.viewer != null) {
			this.viewer.destroy();
		}
		if (this.props.mode == 'edit') {
			this.viewer = new BpmnModeler();
			
			if (this.props.xml == null) {
				//this.viewer.createDiagram();    // Esto te crea el estado de inicio, pero no dispara el evento element-change, no me gusta...
				let xml = '<?xml version="1.0" encoding="UTF-8"?>\
						   <bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn">\
								<bpmn:process id="Process_1" isExecutable="false" />\
									<bpmndi:BPMNDiagram id="BPMNDiagram_1">\
										<bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1" />\
									</bpmndi:BPMNDiagram>\
						   </bpmn:definitions>';
				
				this.viewer.importXML(xml);
			}
			
			this.viewer.on("element.changed", event => {

				this.viewer.saveXML({format: true}, function(error, xml) {
					thisInstance.props.onClick(xml);
				});
			});
		}
		else {
			this.viewer = new BpmnViewer();
		}
		
		if (this.props.xml != null) {
			this.viewer.importXML(this.props.xml);
		}
		
		const container = this.containerRef.current;
		this.viewer.attachTo(container);
	}
	
	componentDidMount() {
		this.refreshData();
	}
	
	componentWillUnmount() {
		this.viewer.destroy();
	}
	
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.mode != this.props.mode) {
			this.refreshData();
		}
	}
	
	render() {
		return (
			<div style={{height: "500px"}} ref={ this.containerRef }></div>
		);
	}
}

BpmnEditor.propTypes = {
	mode: PropTypes.string.isRequired,
	xml: PropTypes.string,
};

export default withStyles(styles)(withContext(withI18n()(BpmnEditor)));
