import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import SigPad from 'signature_pad';
import {debounce} from 'throttle-debounce';

class SignaturePad extends PureComponent {

	static displayName = 'react-signature-pad-wrapper';
	
	static propTypes = {
		width: PropTypes.number,
		height: PropTypes.number,
		options: PropTypes.object,
		redrawOnResize: PropTypes.bool.isRequired,
		debounceInterval: PropTypes.number.isRequired,
		canvasProps: PropTypes.object
	}
	
	static defaultProps = {
		redrawOnResize: false,
		debounceInterval: 150
	}
	
	constructor(props) {
		super(props);
		
		this.state = {canvasWidth: 0, canvasHeight: 0};
		
		this._callResizeHandler = debounce(
			this.props.debounceInterval,
			this.handleResize.bind(this)
		);
	}
	
	componentDidMount() {
		if (this._canvas) {
			if (!this.props.width || !this.props.height) {
				this._canvas.style.width = '100%';
			}
			this.scaleCanvas();
			
			if (!this.props.width || !this.props.height) {
				window.addEventListener('resize', this._callResizeHandler);
			}
			
			this._signaturePad = new SigPad(this._canvas, this.props.options);
		}
	}
	
	componentDidUpdate(prevProps) {
		const ratio = Math.max(window.devicePixelRatio || 1, 1);
		const width = (this.props.width || this._canvas.offsetWidth) * ratio;
		const height = (this.props.height || this._canvas.offsetHeight) * ratio;

		// Avoid needlessly setting height/width if dimensions haven't changed
		const {canvasWidth, canvasHeight} = this.state;

		//console.log(ratio + ", " + width + ", " + height + ", " + canvasWidth + ", " + canvasHeight);
		
		if (width != 0 && height != 0 && canvasWidth == 0 && canvasHeight == 0) {
			// Resize
			//console.log("Resize");
			this._canvas.width = width;
			this._canvas.height = height;
			this.setState({canvasWidth: width, canvasHeight: height});
			this._refresh = true;
		}
		
		else if (width != 0 && height != 0 && canvasWidth != 0 && canvasHeight != 0 && (width != canvasWidth || height != canvasHeight)) {
			// Resize
			//console.log("Resize after tab shown again");
			this._canvas.width = width;
			this._canvas.height = height;
			this.setState({canvasWidth: width, canvasHeight: height});
			this._refresh = true;
		}
		
		else if (width != 0 && height != 0 && canvasWidth != 0 && canvasHeight != 0) {
			// Paint
			//console.log("Paint");
			if (this._refresh) {
				const ctx = this._canvas.getContext('2d');
				ctx.scale(ratio, ratio);
				let data = this._signaturePad.toData();
				this._signaturePad.fromData(data);
			}
		}
	}
	
	componentWillUnmount() {
		if (!this.props.width || !this.props.height) {
			window.removeEventListener('resize', this._callResizeHandler);
		}
		
		this._signaturePad.off();
	}
	
	/**
	 * Get the original signature_pad instance.
	 */
	get instance() {
		return this._signaturePad;
	}
	
	get canvas() {
		return this._canvas;
	}
	
	set dotSize(dotSize) {
		this._signaturePad.dotSize = dotSize;
	}
	
	get dotSize() {
		return this._signaturePad.dotSize;
	}
	
	set minWidth(minWidth) {
		this._signaturePad.minWidth = minWidth;
	}
	
	get minWidth() {
		return this._signaturePad.minWidth;
	}
	
	set maxWidth(maxWidth) {
		this._signaturePad.maxWidth = maxWidth;
	}
	
	get maxWidth() {
		return this._signaturePad.maxWidth;
	}
	
	set throttle(throttle) {
		this._signaturePad.throttle = throttle;
	}
	
	get throttle() {
		return this._signaturePad.throttle;
	}
	
	set backgroundColor(color) {
		this._signaturePad.backgroundColor = color;
	}
	
	get backgroundColor() {
		return this._signaturePad.backgroundColor;
	}
	
	set penColor(color) {
		this._signaturePad.penColor = color;
	}
	
	get penColor() {
		return this._signaturePad.penColor;
	}
	
	set velocityFilterWeight(weight) {
		this._signaturePad.velocityFilterWeight = weight;
	}
	
	get velocityFilterWeight() {
		return this._signaturePad.velocityFilterWeight;
	}
	
	set onBegin(fn) {
		if (!(fn && typeof fn === 'function')) {
			throw new Error('Invalid argument passed to onBegin()');
		}
		
		this._signaturePad.onBegin = fn;
	}
	
	set onEnd(fn) {
		if (!(fn && typeof fn === 'function')) {
			throw new Error('Invalid argument passed to onEnd()');
		}
		
		this._signaturePad.onEnd = fn;
	}
	
	isEmpty() {
		return this._signaturePad.isEmpty();
	}
	
	clear() {
		this._signaturePad.clear();
	}
	
	fromDataURL(base64String) {
		this._signaturePad.fromDataURL(base64String);
	}
	
	toDataURL(mime) {
		return this._signaturePad.toDataURL(mime);
	}
	
	fromData(data) {
		this._signaturePad.fromData(data);
	}
	
	toData() {
		return this._signaturePad.toData();
	}
	
	off() {
		this._signaturePad.off();
	}
	
	on() {
		this._signaturePad.on();
	}
	
	handleResize() {
		this.scaleCanvas();
	}
	
	scaleCanvas() {
		const ratio = Math.max(window.devicePixelRatio || 1, 1);
		const width = (this.props.width || this._canvas.offsetWidth) * ratio;
		const height = (this.props.height || this._canvas.offsetHeight) * ratio;

		// Avoid needlessly setting height/width if dimensions haven't changed
		const {canvasWidth, canvasHeight} = this.state;

		//console.log("scale");
		//console.log(ratio + ", " + width + ", " + height + ", " + canvasWidth + ", " + canvasHeight);

		if (width == 0 || height == 0) {
			//console.log("guay");
			//			this.setState({canvasWidth: 0, canvasHeight: 0});
		}
		else if (width != canvasWidth || height != canvasHeight) {
			this._canvas.width = width;
			this._canvas.height = height;
			this.setState({canvasWidth: width, canvasHeight: height});
		}
		
	}
	
	render() {
		const {canvasProps} = this.props;
		
		return (
			<canvas
				ref={ref => this._canvas = ref}
				{...canvasProps} />
		);
	}
	
}

export default SignaturePad;
