import React from 'react';

import Blockly from 'blockly/core';
import es from 'blockly/msg/es';
import 'blockly/blocks';

Blockly.setLocale(es);

class BlocklyComponent extends React.Component {

	componentDidMount() {
		const { initialXml, children, ...rest } = this.props;
		this.primaryWorkspace = Blockly.inject(
			this.blocklyDiv,
			{
				toolbox: this.toolbox,
				...rest
			},
		);
		
		if (initialXml) {
			Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(initialXml), this.primaryWorkspace);
		}
	}
	
	get workspace() {
		return this.primaryWorkspace;
	}
	
	setXml(xml) {
		Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), this.primaryWorkspace);
	}
	
	render() {
		const { children } = this.props;
		
		return <React.Fragment>
			<div ref={e => this.blocklyDiv = e} id="blocklyDiv" style={{width: "100%", height: "600px", paddingTop: "20px", paddingBottom: "20px"}} />
			<xml xmlns="https://developers.google.com/blockly/xml" is="blockly" style={{ display: 'none' }} ref={(toolbox) => { this.toolbox = toolbox; }}>
				{children}
			</xml>
		</React.Fragment>;
	}
}

export default BlocklyComponent;
